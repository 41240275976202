import { request } from 'utils/api';
import React, { useMemo } from 'react';
import { Skeleton, Timeline } from 'antd';
import { useQuery } from '@tanstack/react-query';

const getOrderTimeline = async (id: string) => {
  const data = await request({
    url: `/orders/order-timeline/${id}`,
    method: 'GET'
  });
  return data;
};

export default function OrderTimeline({ orderId }: { orderId: string }) {
  const { data: OrderTimelineData, isPending: loadingDetails } = useQuery({
    queryKey: ['order_timeline', orderId],
    queryFn: () => getOrderTimeline(orderId)
  });

  const memorizedTimeLineData = useMemo(() => {
    return OrderTimelineData?.timeline ?? [];
  }, [OrderTimelineData?.timeline]);

  return (
    <div className='!mt-[-10px]'>
      {!loadingDetails && (
        <span className='flex flex-row mb-2 w-[9rem] py-1 px-2 rounded-lg bg-emerald-100'>
          <span className='relative flex h-2.5 w-2.5 mt-[6px] mr-1.5'>
            <span className='animate-ping absolute inline-flex h-full w-full rounded-full bg-green opacity-75' />
            <span className='relative inline-flex rounded-full h-2.5 w-2.5 bg-green' />
          </span>
          <span className='font-semibold text-emerald-900'>Live from UPS</span>
        </span>
      )}
      <div className='bg-light-orange rounded-xl p-4 h-[40rem] overflow-auto text-grey'>
        <Skeleton loading={loadingDetails} />
        {memorizedTimeLineData.length ? (
          <Timeline>
            {memorizedTimeLineData.reverse().map((item: any, index: any) => (
              <Timeline.Item key={index} color='orange'>
                <div className='font-semibold text-xs'>
                  <div className='underline'>
                    {item.date} at {item.time}
                  </div>
                  <div>
                    Status:{' '}
                    {item.description.length > 3
                      ? item.description
                      : 'Not Available'}
                  </div>
                  <div>
                    Location:{' '}
                    {item.location.length > 3 ? item.location : 'Not Available'}
                  </div>
                </div>
              </Timeline.Item>
            ))}
          </Timeline>
        ) : (
          !loadingDetails && <span>No data found</span>
        )}
      </div>
    </div>
  );
}
