import { UsersFormInputs } from 'types/users';
import { getUsers, handleUpdateUser } from '.';
import {
  useQuery,
  useMutation,
  UseMutationResult,
  useQueryClient
} from '@tanstack/react-query';

// get users data
export const useUsers = (currentPage: number, rows: number) => {
  return useQuery({
    queryKey: ['usersData'],
    queryFn: () => getUsers(currentPage, rows)
  });
};

// update user
export const useUpdateUser = (
  infoId: string,
  options?: { onSuccess?: () => void }
): UseMutationResult<any, unknown, UsersFormInputs, unknown> => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (data: UsersFormInputs) => handleUpdateUser(data, infoId),
    onSuccess(data) {
      if (data !== undefined) {
        queryClient.invalidateQueries({ queryKey: ['usersData'] });
        options?.onSuccess && options.onSuccess();
      }
    }
  });

  return mutation;
};
