export const getStatusColorClasses = (status: string) => {
  switch (status?.toLowerCase()) {
    case 'pending':
    case 'form pending':
    case 'awaiting ups pickup':
      return 'text-yellow-600 border-yellow-600 bg-amber-100';
    case 'reviewed':
    case 'delivered':
    case 'ready for customer pickup':
      return 'bg-lime-100 text-lime-800 border-lime-800';
    case 'updated delivery date':
    case 'updated delivery time':
      return 'bg-sky-100 text-sky-800 border-sky-800';
    case 'canceled':
    case 'Request Canceled':
      return 'bg-rose-100 text-rose-800 border-rose-800';
    case 'paid':
    case 'yes':
      return 'bg-light-orange text-orange border-orange';
    default:
      return 'border-silver bg-neutral-100 text-silver';
  }
};
