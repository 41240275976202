import React from 'react';
import paths from 'constants/path';
import { Link } from 'react-router-dom';
import formatDate from 'utils/helpers/date';
import EmailBodyView from './EmailBodyView';
import Drawer from 'components/drawer/Drawer';
import { ColumnDef } from '@tanstack/react-table';
import { EmailLogsResponse } from 'types/emailLogs';
import { ArrowRightOutlined, EyeOutlined } from '@ant-design/icons';

export const Columns = (): ColumnDef<EmailLogsResponse>[] => [
  {
    header: 'Created At',
    accessorKey: 'created_at',
    cell: (info) => formatDate(info.getValue<string>())
  },
  {
    header: 'Name',
    accessorKey: 'name'
  },
  {
    header: 'Status',
    accessorKey: 'status'
  },
  {
    header: 'Message Id',
    accessorKey: 'message_id'
  },
  {
    header: 'Updated At',
    accessorKey: 'updated_at',
    cell: (info) => formatDate(info.getValue<string>())
  },
  {
    header: 'Action',
    accessorKey: '',
    cell: (info) => (
      <div className='flex flex-row gap-2'>
        {info.row.original.body && (
          <Drawer
            styles={{
              header: { display: 'flex', flexDirection: 'row-reverse' }
            }}
            width='50%'
            button={<EyeOutlined className='mr-1' />}
            title='Email Body'
            closeIcon={<ArrowRightOutlined />}
          >
            <EmailBodyView info={info.row.original} setIsClose={''} />
          </Drawer>
        )}
        <Link
          to={paths.customer(info.row.original.client_record_id)}
          className='text-orange underline underline-offset-4'
        >
          Customer
        </Link>
        <Link
          to={info.row.original.ghl}
          className='text-orange underline underline-offset-4'
          target='_blank'
        >
          GHL
        </Link>
      </div>
    )
  }
];
