import React from 'react';

const ICAnalytics = ({
  analytics_data,
  dataLoading
}: {
  analytics_data: any;
  dataLoading: boolean;
}) => {
  const {
    ai_processed_count = 0,
    has_reviewed = 0,
    has_invoice_count = 0,
    has_invoice_paid_count = 0,
    has_order_count = 0,
    has_package_count = 0,
    has_pp_count = 0,
    has_sessions_count = 0,
    total_ics_count = 0,
    has_order_note_count = 0
  } = analytics_data || {};

  const details = [
    {
      label: 'Total ICs',
      value: total_ics_count
    },
    {
      label: 'AI Processed',
      value: ai_processed_count
    },
    {
      label: 'Reviewed',
      value: has_reviewed
    },
    {
      label: 'Invoiced',
      value: has_invoice_count
    },
    {
      label: 'Paid Invoices',
      value: has_invoice_paid_count
    },
    {
      label: 'PB Package',
      value: has_package_count
    },
    {
      label: 'PB Payment Plan',
      value: has_pp_count
    },
    {
      label: 'PB Sessions',
      value: has_sessions_count
    },
    {
      label: 'DB Orders',
      value: has_order_count
    },
    {
      label: 'PB Order Notes',
      value: has_order_note_count
    }
  ];

  return (
    <div className='flex flex-row justify-between m-2'>
      {details.map(({ label, value }, index) => (
        <div
          key={index}
          className='flex flex-row justify-between border border-orange bg-light-orange rounded-lg p-1.5'
        >
          <div className='flex flex-col justify-end text-end'>
            <span className='font-semibold text-orange text-sm'>{label}</span>
            <span className='font-medium text-slate-600 text-sm mx-2'>
              {dataLoading ? 'loading...' : value}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ICAnalytics;
