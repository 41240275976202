import React from 'react';
import { ConfigProvider, Radio as RadioComponent, RadioGroupProps } from 'antd';

export default function Radio({ options, ...props }: RadioGroupProps) {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: 'rgb(252 103 32)'
        }
      }}
    >
      <RadioComponent.Group options={options} {...props} />
    </ConfigProvider>
  );
}
