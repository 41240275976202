import { PackagesFormInputs } from 'types/packages';
import {
  getPackages,
  getPackagesName,
  handleAddPackage,
  handleUpdatePackage
} from '.';
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient
} from '@tanstack/react-query';

export const usePackages = (currentPage: number) => {
  return useQuery({
    queryKey: ['packages', currentPage],
    queryFn: () => getPackages(currentPage)
  });
};

export const usePackagesNames = (getNames: boolean) => {
  return useQuery({
    queryKey: ['packages_name'],
    queryFn: () => getPackagesName(),
    enabled: getNames
  });
};

export const useUpdatePackage = (
  infoId: string,
  options?: { onSuccess?: () => void }
): UseMutationResult<any, unknown, PackagesFormInputs, unknown> => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (data: PackagesFormInputs) => handleUpdatePackage(data, infoId),
    onSuccess(data) {
      if (data !== undefined) {
        queryClient.invalidateQueries({ queryKey: ['packages'] });
        options?.onSuccess && options.onSuccess();
      }
    }
  });

  return mutation;
};

export const useCreatePackage = (options?: {
  onSuccess?: () => void;
}): UseMutationResult<any, unknown, PackagesFormInputs, unknown> => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (data: PackagesFormInputs) => handleAddPackage(data),
    onSuccess(data) {
      if (data !== undefined) {
        queryClient.invalidateQueries({ queryKey: ['packages'] });
        options?.onSuccess && options.onSuccess();
      }
    }
  });

  return mutation;
};
