import { useQuery } from '@tanstack/react-query';
import { ProfileResponse } from 'types/profile';
import { getProfile } from '.';

export const useProfile = (token: string | undefined) => {
  return useQuery<ProfileResponse>({
    queryKey: ['profile_data', token],
    queryFn: () => getProfile()
  });
};
