import React from 'react';
import { Spin } from 'antd';
import { useNavigate } from 'react-router-dom';

interface ButtonProps {
  className?: string;
  isLoading?: Boolean;
  children: React.ReactNode;
}
export default function BackButton({
  className,
  isLoading,
  children
}: ButtonProps) {
  const navigate = useNavigate();
  return (
    <button
      type='button'
      className={`${className}`}
      onClick={() => navigate(-1)}
    >
      {isLoading ? <Spin /> : children}
    </button>
  );
}
