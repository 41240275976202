import { ArrowLeftOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Spin } from 'antd';
import { queryClient } from 'App';
import Button from 'components/buttons/Button';
import { Label } from 'components/label/Label';
import Select from 'components/select/Select';
import Textarea from 'components/textarea/Textarea';
import paths from 'constants/path';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { request } from 'utils/api';

interface KnowledgeBaseFormInput {
  model?: string;
  prompt?: string;
}

// const handleAddAiPrompt = async (payload: KnowledgeBaseFormInput) => {
//   const response = await request({
//     url: '/knowledge-bases',
//     method: 'POST',
//     data: payload
//   });
//   return response;
// };

const editAiPrompts = async (
  payload: KnowledgeBaseFormInput,
  knowledge_base_id: string | undefined
) => {
  const response = await request({
    url: `/ai-prompts/${knowledge_base_id}`,
    method: 'PUT',
    data: payload
  });
  return response;
};

const getSingleAiPrompt = async (id: string | undefined) => {
  const data = await request({
    url: `/ai-prompts/${id}`,
    method: 'GET'
  });
  return data;
};

export default function AiPromptForm() {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data: singleAiPrompt, isLoading: isAiPromptLoading } = useQuery({
    queryKey: ['singleAiPrompts', id],
    queryFn: () => getSingleAiPrompt(id),
    enabled: Boolean(id)
  });

  const {
    control,
    handleSubmit,
    formState: { dirtyFields }
  } = useForm({
    values: id
      ? {
          model: singleAiPrompt?.form_request?.model,
          prompt: singleAiPrompt?.form_request?.prompt
        }
      : {}
  });

  // const { mutateAsync: addAiPrompt, isPending: isAddingKnowledgeBase } =
  //   useMutation({
  //     mutationFn: (data: KnowledgeBaseFormInput) => handleAddAiPrompt(data),
  //     onSuccess() {
  //       queryClient.invalidateQueries({ queryKey: ['orders'] });
  //       navigate(paths.knowledgeBase);
  //     }
  //   });

  const { mutateAsync: EditKnowledgeBase, isPending: isEditingKnowledgeBase } =
    useMutation({
      mutationFn: (data: KnowledgeBaseFormInput) => {
        const dirtyData: KnowledgeBaseFormInput = {};
        Object.keys(data).forEach((key) => {
          const objKey = key as keyof KnowledgeBaseFormInput;
          if (dirtyFields[objKey]) {
            dirtyData[objKey] = data[objKey];
          }
        });
        return editAiPrompts(dirtyData, id);
      },
      onSuccess() {
        queryClient.invalidateQueries({ queryKey: ['aiPrompts'] });
        queryClient.invalidateQueries({ queryKey: ['singleAiPrompts'] });
        navigate(paths.aiPrompts);
      }
    });

  const onSubmit = (data: any) => {
    if (id) {
      EditKnowledgeBase(data);
    }
    // else {
    //   addAiPrompt(data);
    // }
  };

  if (isAiPromptLoading) {
    return <Spin fullscreen />;
  }

  return (
    <div className='p-8 bg-[#FAFBFC]'>
      <div className='grid grid-cols-12'>
        <div className='col-start-3 col-span-8'>
          <Link to={paths.aiPrompts}>
            <Button className='flex gap-2 items-center !w-fit px-3 bg-transparent border border-orange !text-orange'>
              <ArrowLeftOutlined />
              Back
            </Button>
          </Link>
          <div className='border border-light-grey rounded py-10 px-6 mt-6 bg-white'>
            <p className='font-bold'>{id ? 'Edit' : 'Create'} Ai Prompt</p>
            <form className='mt-6' onSubmit={handleSubmit(onSubmit)}>
              <Label className='!font-normal text-sm mt-6'>Model*</Label>
              <Controller
                name='model'
                control={control}
                render={({ field }) => (
                  <Select
                    className='w-full mt-2'
                    {...field}
                    placeholder='Select Model'
                    options={[
                      { value: 'gpt-4o', label: 'gpt-4o' },
                      {
                        value: 'gpt-4o-latest',
                        label: 'gpt-4o-latest'
                      },
                      { value: 'gpt-4o-mini', label: 'gpt-4o-mini' },
                      { value: 'gpt-4-turbo', label: 'gpt-4-turbo' },
                      { value: 'gpt-4', label: 'gpt-4' },
                      {
                        value: 'gpt-3.5-turbo-0125',
                        label: 'gpt-3.5-turbo-0125'
                      },
                      { value: 'gpt-3.5-turbo', label: 'gpt-3.5-turbo' }
                    ]}
                  />
                )}
              />
              <Label className='!font-normal text-sm mt-6'>Prompt*</Label>
              <Controller
                name='prompt'
                control={control}
                render={({ field }) => (
                  <Textarea
                    placeholder='Prompt'
                    row={11}
                    type='text'
                    className='mt-2'
                    {...field}
                  />
                )}
              />
              <div className='flex gap-5 justify-end mt-6'>
                <Link to={paths.aiPrompts}>
                  <button className='!w-[148px] py-2 px-6 text-sm !rounded bg-light-grey !text-silver'>
                    Cancel
                  </button>
                </Link>
                <Button
                  className='!w-[148px] px-6 text-sm !rounded'
                  isLoading={isEditingKnowledgeBase}
                >
                  {id ? 'Update' : 'Add Ai Prompt'}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
