import { useQuery } from '@tanstack/react-query';
import React, { useMemo, useState } from 'react';
import { request } from 'utils/api';
import { ColumnDef } from '@tanstack/react-table';
import ActiveIndicator from 'components/activeIndicator/ActiveIndicator';
import { Link } from 'react-router-dom';
import Input from 'components/input/Input';
import Button from 'components/buttons/Button';
import { SearchOutlined } from '@ant-design/icons';
import paths from 'constants/path';
import { Customer } from 'types/customer';
import DataTable from 'components/dataTable/DataTable';
import Pagination from 'components/pagination/Pagination';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import Tooltip from 'components/tooltip/Tooltip';
import Select from 'components/select/Select';
import { joinName } from 'utils/helpers/name';
import { MdOutlineFilterAltOff } from 'react-icons/md';
import formatDate from 'utils/helpers/date';
import { getStatusColorClasses } from 'utils/helpers/statusColourClasses';

interface CustomerFilterInput {
  name: string;
  email: string;
  mobile_phone: string;
  tags: string[];
}

const getCustomers = async (
  currentPage: number,
  params?: CustomerFilterInput
) => {
  const tagsString = params?.tags.join(',');
  const data = await request({
    url: '/clients',
    method: 'GET',
    params: { page: currentPage, ...params, tags: tagsString }
  });
  return data;
};

const getTags = async () => {
  const data = await request({
    url: '/tags',
    method: 'GET'
  });
  return data;
};

export default function CustomersPage() {
  const [currentPage, setCurrentPage] = useState(1);
  const [customerFilters, setCustomerFilters] = useState({
    name: '',
    email: '',
    mobile_phone: '',
    tags: ['']
  });

  const { control, handleSubmit, reset } = useForm<CustomerFilterInput>({
    defaultValues: {}
  });

  const { data: CustomersData, isLoading } = useQuery({
    queryKey: ['customers', currentPage, customerFilters],
    queryFn: () => getCustomers(currentPage, customerFilters)
  });

  const customerMemorizedData = useMemo(() => {
    return CustomersData?.clients ?? [];
  }, [CustomersData?.clients]);

  const onSubmit: SubmitHandler<CustomerFilterInput> = (data) => {
    if (data.tags === undefined) {
      data.tags = [''];
    }
    setCustomerFilters(data);
    setCurrentPage(1);
  };

  const { data: GetTagsData } = useQuery({
    queryKey: ['tagsData'],
    queryFn: () => getTags()
  });

  return (
    <div className='p-5 bg-[#FAFBFC]'>
      <div className='border border-light-grey bg-white rounded-xl p-2.5'>
        <h1 className='text-xl font-semibold'>Customers</h1>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='filters flex gap-6 items-center my-4'>
            <Controller
              name='name'
              control={control}
              render={({ field }) => (
                <Input placeholder='Name' type='text' {...field} />
              )}
            />
            <Controller
              name='email'
              control={control}
              render={({ field }) => (
                <Input placeholder='Email' type='email' {...field} />
              )}
            />
            <Controller
              name='mobile_phone'
              control={control}
              render={({ field }) => (
                <Input placeholder='Phone No.' type='tel' {...field} />
              )}
            />
            <Controller
              name='tags'
              control={control}
              render={({ field }) => (
                <Select
                  mode='multiple'
                  allowClear
                  placeholder='Select Tags'
                  maxTagCount='responsive'
                  className='w-[300px] h-[38px]'
                  maxTagPlaceholder={(omittedValues) => (
                    <Tooltip
                      title={omittedValues.map(({ label }) => label).join(', ')}
                    >
                      <span>+{omittedValues.length}</span>
                    </Tooltip>
                  )}
                  options={GetTagsData?.map(
                    (data: { id: string; name: string }) => ({
                      value: data.id,
                      label: data.name
                    })
                  )}
                  {...field}
                />
              )}
            />
            <Button className='w-max px-3.5 h-[38px]'>
              <SearchOutlined className='!text-white' />
            </Button>
            <button
              onClick={() =>
                reset({
                  name: '',
                  email: '',
                  mobile_phone: '',
                  tags: []
                })
              }
              className='border border-orange rounded py-2.5 px-3'
            >
              <MdOutlineFilterAltOff className='text-orange' />
            </button>
          </div>
        </form>
        <div className='overflow-auto h-[calc(100vh_-_280px)]'>
          <DataTable
            isLoading={isLoading}
            data={customerMemorizedData}
            columns={columns}
          />
        </div>
      </div>
      <Pagination
        isLoading={isLoading}
        totalCount={CustomersData?.pagination.total_count}
        totalPage={CustomersData?.pagination.total_pages}
        perPage={CustomersData?.pagination.per_page}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
}

const columns: ColumnDef<Customer>[] = [
  {
    header: 'Name',
    accessorKey: 'first_name',
    cell: (info) => (
      <a
        href={`https://my.practicebetter.io/#/p/clients/${info.row.original.id}`}
        rel='noopener noreferrer'
        target='_blank'
        className='flex items-center gap-2'
      >
        <ActiveIndicator isActive={info.row.original.is_active} />
        <p className='underline text-orange underline-offset-4'>
          {joinName(info.getValue<string>(), info.row.original.last_name)}
        </p>
      </a>
    )
  },
  {
    header: 'Email',
    accessorKey: 'email',
    cell: (info) => (
      <p className='text-orange underline truncate max-w-32'>
        <Tooltip title={info.getValue<string>()}>
          {info.getValue<string>()}
        </Tooltip>
      </p>
    )
  },
  {
    header: 'Tags',
    accessorKey: 'related_tags',
    cell: (info) =>
      info.row.original.related_tags &&
      info.row.original.related_tags.length > 0 ? (
        <div className='flex items-center gap-2 relative'>
          <div className='w-fit border border-orange rounded-full py-1 px-2 text-xs text-orange bg-light-orange text-center truncate'>
            {info.row.original.related_tags[0]}
          </div>
          {info.row.original.related_tags.length > 1 && (
            <div className='group'>
              <p className='text-orange underline underline-offset-4'>
                +{info.row.original.related_tags.length}
              </p>
              <div className='absolute hidden group-hover:flex gap-2 flex-wrap w-60 px-2 py-3 rounded-xl bg-white border shadow-custom z-10'>
                {info.row.original.related_tags.map(
                  (relatedTag: string, index: number) => (
                    <p
                      key={index}
                      className='w-fit border border-orange rounded-full py-1 px-2 text-xs text-orange bg-light-orange text-center'
                    >
                      {relatedTag}
                    </p>
                  )
                )}
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className='w-fit border border-silver text-silver rounded-full py-1 px-2 text-xs text-center'>
          No Tags set
        </div>
      )
  },
  {
    header: 'Medication',
    accessorKey: 'medication',
    cell: (info) =>
      info.getValue() && (
        <p className='border border-blue rounded-full py-1 px-2 text-xs text-blue bg-light-blue text-center'>
          {info.getValue() as string}
        </p>
      )
  },
  {
    header: 'Previous Order',
    accessorKey: 'previous_order',
    cell: (info) => formatDate(info.getValue<string>())
  },
  {
    header: 'Tracking No.',
    accessorKey: 'tracking_number',
    cell: (info) => info.getValue()
  },
  {
    header: 'Order Status',
    accessorKey: 'order_status',
    cell: (info) =>
      info.getValue() && (
        <p
          className={`border rounded-full py-1 px-2 text-xs text-center w-[8rem] ${getStatusColorClasses(info.getValue() as string)}`}
        >
          {info.getValue() as string}
        </p>
      )
  },
  {
    header: 'Action',
    accessorKey: '',
    cell: (info) => (
      <Link
        to={paths.customer(info.row.original.id)}
        className='text-orange underline underline-offset-4'
      >
        View
      </Link>
    )
  }
];
