import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient
} from '@tanstack/react-query';
import {
  getTasks,
  handleBulkSessionCreation,
  handleSessionCreation,
  handleUpdateStatus
} from '.';

// get tasks
export const useTasks = (
  currentPage: number,
  tasksFilters: any,
  sortingDetails: any
) => {
  return useQuery({
    queryKey: ['tasks', currentPage, tasksFilters, sortingDetails],
    queryFn: () =>
      getTasks(currentPage, tasksFilters, undefined, sortingDetails)
  });
};

// Creating session
export const useCreateSession = (options?: {
  onSuccess?: () => void;
}): UseMutationResult<any, unknown, string, unknown> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: string) => handleSessionCreation(id),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['tasks'] });
      queryClient.refetchQueries({ queryKey: ['tasks'] });
      options?.onSuccess && options.onSuccess();
    }
  });
};

// Creating bulk sessions
export const useCreateBulkSessions = (
  selectedRows: any[],
  options?: { onSuccess?: () => void }
): UseMutationResult<any, unknown, void, unknown> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => handleBulkSessionCreation(selectedRows),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['tasks'] });
      queryClient.refetchQueries({ queryKey: ['tasks'] });
      options?.onSuccess && options.onSuccess();
    }
  });
};

// Archiving data
export const useArchiveData = (
  selectedIds: string[],
  options?: { onSuccess?: () => void }
): UseMutationResult<any, unknown, string[], unknown> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (ids: string[]) => handleUpdateStatus(ids),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['tasks'] });
      queryClient.refetchQueries({ queryKey: ['tasks'] });
      options?.onSuccess && options.onSuccess();
    }
  });
};
