import axios from 'axios';
import { getCookie } from './cookies';
import { notification } from 'antd';

const client = axios.create({
  baseURL: process.env.REACT_APP_HOST_URL,
  headers: {
    'X-API-KEY': process.env.REACT_APP_API_KEY
  }
});

export interface RequestOptions {
  url: string;
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
  data?: any;
  params?: any;
}

const throwErrorNotification = (message: string) => {
  notification['error']({
    placement: 'bottomLeft',
    message: message
  });
};

const throwSuccessNotification = (message: string) => {
  notification['success']({
    placement: 'bottomLeft',
    message: message
  });
};

const throwWarningNotification = (message: string) => {
  notification['warning']({
    placement: 'bottomLeft',
    message: message
  });
};

client.interceptors.response.use(
  (response) => {
    const successMessage = response.data;
    if (successMessage?.message && !successMessage.payload?.hide_notification) {
      throwSuccessNotification(successMessage.message);
    }
    return response;
  },
  (error) => {
    const axiosError = error as any;
    const errorMessage =
      axiosError.response?.data?.error || 'An unexpected error occurred';
    if (axiosError.response?.data?.warning) {
      throwWarningNotification(axiosError.response?.data?.warning);
    } else if (axiosError.response?.data?.error?.length > 0) {
      axiosError.response?.data?.error?.map((item: any) =>
        throwErrorNotification(
          `${item.loc[0].replace(/_/g, ' ')} - ${item.msg}`
        )
      );
    } else {
      throwErrorNotification(errorMessage);
    }
    return Promise.reject(error);
  }
);

export const request = async (options: RequestOptions) => {
  let token = getCookie('token');
  try {
    if (token) {
      client.defaults.headers.common.Authorization = `Bearer ${token}`;
    }

    const response = await client(options);
    return response?.data;
  } catch (error) {
    return;
  }
};
