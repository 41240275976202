import React from 'react';
import Input from 'components/input/Input';
import Button from 'components/buttons/Button';
import Select from 'components/select/Select';
import { Label } from 'components/label/Label';
import { TasksFilterInput } from 'types/tasks';
import { SearchOutlined } from '@ant-design/icons';
import { UseQueryResult } from '@tanstack/react-query';
import { MdOutlineFilterAltOff } from 'react-icons/md';
import { useUsers } from 'pages/settings/pages/users/api/useApi';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';

type FiltersProps = {
  setTasksFilters: (data: TasksFilterInput) => void;
  setCurrentPage: (page: number) => void;
  tasksFilters: Partial<TasksFilterInput>;
  default_pb_consultant_id: string;
  role: string | undefined;
};

type UserDataType = {
  users: Array<{
    pb_consultant_id: string;
    first_name: string;
    last_name: string;
  }>;
};

export default function Filters({
  setTasksFilters,
  setCurrentPage,
  tasksFilters,
  default_pb_consultant_id,
  role
}: FiltersProps) {
  const { data: usersData }: UseQueryResult<UserDataType, Error> = useUsers(
    1,
    30
  );
  const { control, handleSubmit, reset } = useForm<TasksFilterInput>({
    defaultValues: {
      ...tasksFilters
    }
  });

  const onSubmit: SubmitHandler<TasksFilterInput> = (data) => {
    setTasksFilters(data);
    setCurrentPage(1);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='filters flex gap-6 items-center my-4'>
        <div className='w-40'>
          <Label htmlFor='name' className='text-sm !font-normal m-1'>
            Name
          </Label>
          <Controller
            name='name'
            control={control}
            render={({ field }) => (
              <Input type='text' id='name' className='h-[2rem]' {...field} />
            )}
          />
        </div>
        <div className='w-44'>
          <Label htmlFor='email' className='text-sm !font-normal m-1'>
            Email
          </Label>
          <Controller
            name='email'
            control={control}
            render={({ field }) => (
              <Input type='email' id='email' className='h-[2rem]' {...field} />
            )}
          />
        </div>
        <div>
          <Label htmlFor='completed' className='text-sm !font-normal m-1'>
            Completed
          </Label>
          <Controller
            name='completed'
            control={control}
            render={({ field }) => (
              <Select
                id='completed'
                options={[
                  { value: 'true', label: 'Yes' },
                  { value: 'false', label: 'No' }
                ]}
                className='w-[150px]'
                {...field}
              />
            )}
          />
        </div>
        {role === 'Super Admin' && (
          <div>
            <Label htmlFor='consultant_id' className='text-sm !font-normal m-1'>
              Consultant
            </Label>
            <Controller
              name='consultant_id'
              control={control}
              render={({ field }) => (
                <Select
                  id='consultant_id'
                  options={usersData?.users
                    ?.filter((user) => !!user.pb_consultant_id)
                    .map((user) => ({
                      value: user.pb_consultant_id,
                      label: `${user.first_name} ${user.last_name}`
                    }))}
                  className='w-[190px]'
                  {...field}
                />
              )}
            />
          </div>
        )}
        {/* <div>
          <Label htmlFor='archived' className='text-sm !font-normal m-1'>
            Archived
          </Label>
          <Controller
            name='archived'
            control={control}
            render={({ field }) => (
              <Select
                id='archived'
                options={[
                  { value: 'true', label: 'Yes' },
                  { value: 'false', label: 'No' }
                ]}
                className='w-[150px]'
                {...field}
              />
            )}
          />
        </div> */}
        <Button type='submit' className='w-max px-3.5 h-[38px] mt-5'>
          <SearchOutlined className='!text-white' />
        </Button>
        <button
          onClick={() =>
            reset({
              name: '',
              email: '',
              completed: '',
              consultant_id: default_pb_consultant_id
            })
          }
          className='border border-orange rounded py-2.5 px-3 mt-5'
        >
          <MdOutlineFilterAltOff className='text-orange' />
        </button>
      </div>
    </form>
  );
}
