import { useMutation, useQueryClient } from '@tanstack/react-query';
import Button from 'components/buttons/Button';
import DatePicker from 'components/datepicker/DatePicker';
import Input from 'components/input/Input';
import { Label } from 'components/label/Label';
import Select from 'components/select/Select';
import dayjs, { Dayjs } from 'dayjs';
import { useOrderStatuses } from 'pages/orders/Orders';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { request } from 'utils/api';
import formatDate from 'utils/helpers/date';

interface OrderFormInputs {
  order_date?: string | null;
  tracking_number?: string;
  pharmacy?: string;
  vial_size?: number;
  initial_dose?: number;
  status?: string;
  medication?: string;
  units?: number;
  ups_status?: string;
}

const handleUpdateOrder = async (payload: OrderFormInputs, id: string) => {
  const response = await request({
    url: `/orders/${id}`,
    method: 'PUT',
    data: payload
  });
  return response;
};

const handleAddOrder = async (payload: OrderFormInputs, clientId: string) => {
  const response = await request({
    url: '/orders',
    method: 'POST',
    data: payload,
    params: { client_id: clientId }
  });
  return response;
};

export default function OrderForm({ info, setIsClose }: any) {
  const { id } = useParams();

  const queryClient = useQueryClient();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, dirtyFields }
  } = useForm<OrderFormInputs>({
    values: info
      ? {
          order_date: formatDate(info.order_date),
          tracking_number: info.tracking_number,
          pharmacy: info.pharmacy,
          vial_size: info.vial_size,
          initial_dose: info.initial_dose,
          ups_status: info.ups_status,
          medication: info.medication,
          units: info.units
        }
      : {
          order_date: '',
          tracking_number: '',
          pharmacy: null,
          vial_size: '',
          initial_dose: '',
          ups_status: '',
          medication: null,
          units: ''
        }
  });

  const { mutateAsync: updateOrder, isPending: isUpdatePending } = useMutation({
    mutationFn: (data: OrderFormInputs) => handleUpdateOrder(data, info.id),
    onSuccess(data) {
      if (data !== undefined) {
        queryClient.invalidateQueries({ queryKey: ['orders'] });
        setIsClose();
      }
    }
  });

  const { mutateAsync: addOrder, isPending: isAddPending } = useMutation({
    mutationFn: (data: OrderFormInputs) => handleAddOrder(data, String(id)),
    onSuccess(data) {
      reset();
      if (data !== undefined) {
        queryClient.invalidateQueries({ queryKey: ['orders'] });
        setIsClose();
      }
    }
  });

  const onSubmit = (data: any) => {
    const dirtyData: OrderFormInputs = {};
    Object.keys(data).forEach((key) => {
      const objKey = key as keyof OrderFormInputs;
      if (dirtyFields[objKey]) {
        dirtyData[objKey] = data[objKey];
      }
    });
    if (info) {
      updateOrder(dirtyData);
    } else {
      addOrder(dirtyData);
    }
  };
  const isPending = isUpdatePending || isAddPending;
  const { data: orderStatuses } = useOrderStatuses();

  return (
    <>
      <p className='text-base font-semibold'>Orders Detail</p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='grid grid-cols-2 mt-3.5 gap-6'>
          <div>
            <Label className='!font-normal'>Order Date*</Label>
            <Controller
              control={control}
              rules={{ required: 'Order Date is Required' }}
              name='order_date'
              render={({ field }) => (
                <DatePicker
                  className='!px-2.5 !py-1.5'
                  format='MM-DD-YYYY'
                  {...field}
                  onChange={(date: Dayjs | null) => {
                    field.onChange(date ? date.format('MM/DD/YYYY') : null);
                  }}
                  value={field.value ? dayjs(field.value) : null}
                />
              )}
            />
            <p className='text-red text-sm'>{errors?.order_date?.message}</p>
          </div>
          <div>
            <Label className='!font-normal'>Vial Size*</Label>
            <Controller
              name='vial_size'
              rules={{ required: 'Vial Size is Required' }}
              control={control}
              render={({ field }) => (
                <Input type='number' className='mt-2' {...field} />
              )}
            />
            <p className='text-red text-sm'>{errors?.vial_size?.message}</p>
          </div>
          <div>
            <Label className='!font-normal'>Initial Dose*</Label>
            <Controller
              name='initial_dose'
              rules={{ required: 'Initial Dose is Required' }}
              control={control}
              render={({ field }) => (
                <Input type='number' className='mt-2' {...field} />
              )}
            />
            <p className='text-red text-sm'>{errors?.initial_dose?.message}</p>
          </div>
          <div>
            <Label className='!font-normal'>Medication</Label>
            <Controller
              name='medication'
              control={control}
              render={({ field }) => (
                <Select
                  className='w-full mt-2 h-[38px]'
                  {...field}
                  placeholder='Select'
                  options={[
                    { value: 'Semaglutide', label: 'Semaglutide' },
                    { value: 'Tirzepatide', label: 'Tirzepatide' }
                  ]}
                />
              )}
            />
          </div>
          <div>
            <Label className='!font-normal'>Tracking Number</Label>
            <Controller
              name='tracking_number'
              control={control}
              render={({ field }) => (
                <Input type='number' className='mt-2' {...field} />
              )}
            />
          </div>
          <div>
            <Label className='!font-normal'>Pharmacy</Label>
            <Controller
              name='pharmacy'
              control={control}
              render={({ field }) => (
                <Select
                  className='w-full mt-2 h-[38px]'
                  {...field}
                  placeholder='Select'
                  options={[
                    { value: 'Hallandale', label: 'Hallandale' },
                    { value: 'Redrock', label: 'Redrock' }
                  ]}
                />
              )}
            />
          </div>
          <div>
            <Label className='!font-normal'>Units</Label>
            <Controller
              name='units'
              control={control}
              render={({ field }) => (
                <Input
                  type='number'
                  className='mt-2'
                  {...field}
                  onChange={(event) => field.onChange(+event.target.value)}
                />
              )}
            />
          </div>
          <div>
            <Label className='!font-normal'>Status</Label>
            <Controller
              name='ups_status'
              control={control}
              render={({ field }) => (
                <Select
                  className='w-full mt-2 h-[38px]'
                  {...field}
                  placeholder='Select'
                  options={orderStatuses?.ups_status.map((val: string) => ({
                    label: val,
                    value: val
                  }))}
                />
              )}
            />
          </div>
        </div>
        <div className='flex gap-5 bg-white justify-end absolute bottom-0 right-0 px-4 py-3 border-t w-full'>
          <Button className='!w-fit py-3 px-8' isLoading={isPending}>
            Submit
          </Button>
        </div>
      </form>
    </>
  );
}
