import React from 'react';
import PackageForm from './PackageForm';
import Drawer from 'components/drawer/Drawer';
import Button from 'components/buttons/Button';
import { ArrowRightOutlined, PlusOutlined } from '@ant-design/icons';

export default function HeaderActionButtons() {
  return (
    <div>
      <Drawer
        styles={{
          header: { display: 'flex', flexDirection: 'row-reverse' }
        }}
        width='50%'
        button={
          <Button className='!w-fit px-4 py-2.5 text-xs'>
            <PlusOutlined className='mr-2' />
            Add New Package
          </Button>
        }
        title='Edit Package'
        closeIcon={<ArrowRightOutlined />}
      >
        <PackageForm />
      </Drawer>
    </div>
  );
}
