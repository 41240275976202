import { Avatar } from 'antd';
import { getCookie } from 'utils/cookies';
import { useProfile } from './api/useApi';
import { ArrowLeftOutlined } from '@ant-design/icons';
import BackButton from 'components/buttons/BackButton';

export default function Profile() {
  const token = getCookie('token');
  const { data: profileData, isPending: loadingData } = useProfile(token);
  const profileDetails = [
    {
      label: 'Name',
      value: `${profileData?.first_name || ''} ${profileData?.last_name || ''}`
    },
    { label: 'Email', value: profileData?.email || 'N/A' },
    { label: 'Phone Number', value: profileData?.phone_number || 'N/A' },
    { label: 'Role', value: profileData?.role || 'N/A' },
    { label: 'Username', value: profileData?.user_name || 'N/A' },
    {
      label: 'PB Consultant Id',
      value: profileData?.pb_consultant_id || 'N/A'
    },
    {
      label: 'Practitioner',
      value: profileData?.is_practitioner ? 'Yes' : 'No'
    },
    { label: 'Assistant', value: profileData?.is_assistant ? 'Yes' : 'No' }
  ];

  return (
    <div className='p-5 bg-[#FAFBFC] h-[45rem]'>
      <div className='flex justify-between'>
        <BackButton className='!h-9 flex gap-2 items-center !w-fit px-3 bg-transparent border border-orange !text-orange rounded-lg'>
          <ArrowLeftOutlined />
          Back
        </BackButton>

        <div className='mx-auto'>
          <div className='h-35 w-35 rounded-full shadow-lg mr-11'>
            <Avatar
              className='w-20 h-20 block mx-auto'
              src='https://api.dicebear.com/7.x/miniavs/svg?seed=1'
            />
          </div>
        </div>
      </div>
      <div className='col-span-2 rounded-xl border border-light-grey w-[35rem] h-[35rem] mx-auto mt-5 p-5 bg-white shadow-lg '>
        <span className='font-semibold text-orange'>
          {loadingData ? 'Loading Details...' : 'Profile Details'}
        </span>
        <div
          className={`h-[30rem] overflow-auto mt-3 ${loadingData && 'opacity-50'}`}
        >
          {profileDetails.map(({ label, value }, index) => (
            <div key={index} className='border-b mt-3 p-2'>
              <span className='font-light mr-2 text-orange'>{label}: </span>
              <span className='font-medium'>{loadingData ? '' : value}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
