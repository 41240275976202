import { ConfigProvider, DatePicker as DatePickerComponent } from 'antd';
import { RangePickerProps as AntdRangePickerProps } from 'antd/es/date-picker';

interface RangePickerProps extends AntdRangePickerProps {
  className?: string;
}

const { RangePicker: AntdRangePicker } = DatePickerComponent;

export default function RangePicker({ className, ...props }: RangePickerProps) {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: 'rgb(252 103 32)'
        }
      }}
    >
      <AntdRangePicker
        format='MM-DD-YYYY'
        className={`py-3 px-5 ${className}`}
        {...props}
      />
    </ConfigProvider>
  );
}
