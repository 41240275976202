import React, { useMemo, useState } from 'react';
import { useUsers } from './api/useApi';
import { columns } from './components/Columns';
import DataTable from 'components/dataTable/DataTable';
import Pagination from 'components/pagination/Pagination';

export default function UsersPage() {
  const [currentPage, setCurrentPage] = useState(1);
  const { data: usersData, isPending: isLoading } = useUsers(currentPage, 30);

  const memoizedUsersData = useMemo(() => {
    return usersData?.users ?? [];
  }, [usersData?.users]);

  return (
    <div className='p-5 bg-[#FAFBFC] min-h-[42rem]'>
      <div className='border border-light-grey bg-white rounded-xl p-2.5'>
        <h1 className='text-xl font-semibold mb-2'>Users</h1>
        <div className='overflow-auto h-[calc(100vh_-_300px)]'>
          <DataTable
            isLoading={isLoading}
            data={memoizedUsersData}
            columns={columns()}
          />
        </div>
      </div>
      <Pagination
        isLoading={isLoading}
        totalCount={usersData?.pagination.total_count}
        totalPage={usersData?.pagination.total_pages}
        perPage={usersData?.pagination.per_page}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
}
