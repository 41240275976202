import React from 'react';
import { Spin } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import { useSyncInitialConsultations } from '../api/useApi';

interface HeaderActionButtonsProps {
  selectedRows: string[];
  isArchivePending: boolean;
  archiveData: any;
}

export default function HeaderActionButtons({
  selectedRows,
  isArchivePending,
  archiveData
}: HeaderActionButtonsProps) {
  const { isLoading: isSyncPending, refetch: syncInitialConsultationForms } =
    useSyncInitialConsultations();
  return (
    <div className='flex gap-5'>
      <button
        disabled={!(selectedRows.length > 1) || isArchivePending}
        className='border border-1 border-orange px-2 py-1 bg-orange text-white rounded-lg w-32 disabled:opacity-50 disabled:cursor-not-allowed'
        onClick={() => archiveData([...selectedRows])}
      >
        {isArchivePending ? <Spin /> : 'Archive'}
      </button>
      <button
        disabled={isSyncPending}
        className='border border-1 border-orange px-2 py-1 bg-orange text-white rounded-lg w-28 disabled:opacity-50 disabled:cursor-not-allowed'
        onClick={() => syncInitialConsultationForms()}
      >
        {isSyncPending ? (
          <Spin />
        ) : (
          <>
            <SyncOutlined className='mr-1' />
            Sync
          </>
        )}
      </button>
    </div>
  );
}
