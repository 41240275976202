import React, { useMemo, useState } from 'react';
import { Modal } from 'antd';
import { getCookie } from 'utils/cookies';
import Filters from './components/Filters';
import Drawer from 'components/drawer/Drawer';
import { columns } from './components/Columns';
import DataTable from 'components/dataTable/DataTable';
import SendEmailForm from './components/SendEmailForm';
import Pagination from 'components/pagination/Pagination';
import { RowSelectionState } from '@tanstack/react-table';
import HeaderActionButtons from './components/HeaderActionButtons';
import { useArchiveData, useInitialConsultations } from './api/useApi';
import InitialConsultationsForm from './components/initialConsultationForm/InitialConsultationsForm';
import paths from 'constants/path';
import { useParams } from 'react-router-dom';

export default function InitialConsultations({
  isSingle
}: {
  isSingle?: boolean;
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const [processingModalId, setProcessingModalId] = useState('');
  const pbConsultantId = getCookie('pb_consultant_id');
  const role = getCookie('role');
  const { id } = useParams();

  const default_pb_consultant_id =
    role === 'Super Admin' || !pbConsultantId || pbConsultantId === 'null'
      ? ''
      : String(pbConsultantId);
  const [initialConsultationFilters, setInitialConsultationFilters] = useState({
    first_name: '',
    last_name: '',
    email: '',
    status: 'Pending',
    consultant_id: default_pb_consultant_id,
    completed: '',
    completed_at: []
  });
  const [sortingDetails, setSortingDetails] = useState({
    sort_by: 'created_at',
    sort_order: 'desc'
  });

  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const selectedRows = useMemo(() => Object.keys(rowSelection), [rowSelection]);

  const handleSuccess = () => {
    setRowSelection({});
  };

  const { mutateAsync: archiveData, isPending: isArchivePending } =
    useArchiveData({ onSuccess: handleSuccess });
  const { data: InitialConsultationsData, isLoading } = useInitialConsultations(
    currentPage,
    initialConsultationFilters,
    sortingDetails
  );
  const initialConsultationsMemorizedData = useMemo(() => {
    return InitialConsultationsData?.form_requests ?? [];
  }, [InitialConsultationsData?.form_requests]);

  return (
    <div className='p-5 bg-[#FAFBFC]'>
      <div className='border border-light-grey bg-white rounded-xl p-2.5'>
        <div className='flex justify-between'>
          <h1 className='text-xl font-semibold'>Initial Consultations</h1>
          <HeaderActionButtons
            selectedRows={selectedRows}
            isArchivePending={isArchivePending}
            archiveData={archiveData}
          />
        </div>
        <Filters
          role={role}
          setCurrentPage={setCurrentPage}
          initialConsultationFilters={initialConsultationFilters}
          default_pb_consultant_id={default_pb_consultant_id}
          setInitialConsultationFilters={setInitialConsultationFilters}
        />
        <div className='overflow-auto h-[calc(100vh_-_310px)]'>
          <DataTable
            isLoading={isLoading}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            data={initialConsultationsMemorizedData}
            columns={columns(
              archiveData,
              setSortingDetails,
              sortingDetails,
              setProcessingModalId
            )}
          />
        </div>
      </div>
      <Pagination
        isLoading={isLoading}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        perPage={InitialConsultationsData?.pagination.per_page}
        totalPage={InitialConsultationsData?.pagination.total_pages}
        totalCount={InitialConsultationsData?.pagination.total_count}
      />
      <Modal
        open={Boolean(processingModalId)}
        onCancel={() => setProcessingModalId('')}
        footer={[]}
        maskClosable={false}
      >
        <SendEmailForm
          key={processingModalId}
          setProcessingModalId={setProcessingModalId}
          processingModalId={processingModalId}
        />
      </Modal>
      <Drawer
        width='90%'
        button={null}
        closeIcon={null}
        open={Boolean(isSingle)}
        styles={{ header: { display: 'flex', flexDirection: 'row-reverse' } }}
      >
        <InitialConsultationsForm
          prevUrl={paths.initialConsultations}
          isSingle={isSingle}
          formId={String(id)}
        />
      </Drawer>
    </div>
  );
}
