import React from 'react';

export default function ICPackageDetails({
  getSelectedNames,
  currentPackage
}: {
  getSelectedNames: any;
  currentPackage: {
    pb_package_name: string;
    invoice_amount: number;
    discount_tag: string;
    payment_plan_amount: number;
    payment_plan_duration: string;
  };
}) {
  const fieldsLeft = [
    { label: 'Invoice Amount', value: `$${currentPackage.invoice_amount}` },
    { label: 'Discount Tag', value: currentPackage.discount_tag }
  ];

  const fieldsRight = [
    {
      label: 'Payment Plan Amount',
      value: `$${currentPackage.payment_plan_amount}`
    },
    {
      label: 'Payment Plan Duration',
      value: currentPackage.payment_plan_duration
    }
  ];

  return (
    <div className='border border-1 rounded p-2 my-3 mx-5 bg-light-orange'>
      <div className='font-medium border-b m-2 pb-1 text-xs'>
        Package ({getSelectedNames(currentPackage.pb_package_name)})
      </div>
      <div className='flex flex-row text-xs'>
        {[fieldsLeft, fieldsRight].map((fields, index) => (
          <div
            key={index}
            className={`flex flex-col w-1/2 ${index === 0 ? 'border-r mr-3' : ''}`}
          >
            {fields.map((field, i) => (
              <span key={i} className='flex flex-row'>
                <span className='font-medium'>{field.label}:</span>
                <span className='ml-1'>{field.value}</span>
              </span>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}
