import React, { useMemo, useState } from 'react';
import { getCookie } from 'utils/cookies';
import Filters from './components/Filters';
import { Columns } from './components/Columns';
import DataTable from 'components/dataTable/DataTable';
import { useArchiveData, useTasks } from './api/useApi';
import Pagination from 'components/pagination/Pagination';
import { RowSelectionState } from '@tanstack/react-table';
import HeaderActionButtons from './components/HeaderActionButtons';

export default function TasksPage() {
  const [currentPage, setCurrentPage] = useState(1);
  const pbConsultantId = getCookie('pb_consultant_id');
  const role = getCookie('role');
  const default_pb_consultant_id =
    role === 'Super Admin' || !pbConsultantId || pbConsultantId === 'null'
      ? ''
      : String(pbConsultantId);
  const [tasksFilters, setTasksFilters] = useState({
    name: '',
    email: '',
    completed: '',
    consultant_id: default_pb_consultant_id
  });
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const selectedRows = useMemo(() => Object.keys(rowSelection), [rowSelection]);
  const [sortingDetails, setSortingDetails] = useState({
    sort_by: 'created_at',
    sort_order: 'desc'
  });
  const { data: tasksData, isLoading } = useTasks(
    currentPage,
    tasksFilters,
    sortingDetails
  );

  const handleSuccess = () => {
    setRowSelection({});
  };

  const { mutateAsync: updateStatus, isPending: isStatusUpdating } =
    useArchiveData(selectedRows, { onSuccess: handleSuccess });

  const memoizedTasksData = useMemo(() => {
    return tasksData?.tasks ?? [];
  }, [tasksData?.tasks]);

  return (
    <div className='p-5 bg-[#FAFBFC]'>
      <div className='border border-light-grey bg-white rounded-xl p-2.5'>
        <div className='flex justify-between items-center'>
          <h1 className='text-xl font-semibold mb-2'>Tasks</h1>
          <HeaderActionButtons
            selectedRows={selectedRows}
            isStatusUpdating={isStatusUpdating}
            updateStatus={updateStatus}
            currentPage={currentPage}
            tasksFilters={tasksFilters}
            handleSuccess={handleSuccess}
          />
        </div>
        <Filters
          setTasksFilters={setTasksFilters}
          setCurrentPage={setCurrentPage}
          tasksFilters={tasksFilters}
          default_pb_consultant_id={default_pb_consultant_id}
          role={role}
        />
        <div className='overflow-auto h-[calc(100vh_-_320px)]'>
          <DataTable
            isLoading={isLoading}
            data={memoizedTasksData}
            setRowSelection={setRowSelection}
            rowSelection={rowSelection}
            columns={Columns(
              sortingDetails,
              setSortingDetails,
              updateStatus,
              handleSuccess
            )}
          />
        </div>
      </div>
      <Pagination
        isLoading={isLoading}
        totalCount={tasksData?.pagination.total_count}
        totalPage={tasksData?.pagination.total_pages}
        perPage={tasksData?.pagination.per_page}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
}
