import React, { useMemo, useState } from 'react';
import { useEmailLogs } from './api/useApi';
import { Columns } from './components/Columns';
import DataTable from 'components/dataTable/DataTable';
import Pagination from 'components/pagination/Pagination';

export default function EmailLogsPage() {
  const [currentPage, setCurrentPage] = useState(1);

  const { data: emailLogsData, isLoading } = useEmailLogs(currentPage);

  const memoizedEmailLogsData = useMemo(() => {
    return emailLogsData?.email_logs ?? [];
  }, [emailLogsData?.email_logs]);

  return (
    <div className='p-5 bg-[#FAFBFC]'>
      <div className='border border-light-grey bg-white rounded-xl p-2.5'>
        <h1 className='text-xl font-semibold mb-2'>Email Logs</h1>
        <div className='overflow-auto h-[calc(100vh_-_250px)]'>
          <DataTable
            isLoading={isLoading}
            data={memoizedEmailLogsData}
            columns={Columns()}
          />
        </div>
      </div>
      <Pagination
        isLoading={isLoading}
        totalCount={emailLogsData?.pagination.total_count}
        totalPage={emailLogsData?.pagination.total_pages}
        perPage={emailLogsData?.pagination.per_page}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
}
