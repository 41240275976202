import React, { useMemo, useState } from 'react';
import paths from 'constants/path';
import Filters from './components/Filters';
import { useParams } from 'react-router-dom';
import Drawer from 'components/drawer/Drawer';
import { Columns } from './components/Columns';
import DataTable from 'components/dataTable/DataTable';
import Pagination from 'components/pagination/Pagination';
import { useAutomateInvoice, useInvoices } from './api/useApi';
import HeaderActionButtons from './components/HeaderActionButtons';
import InitialConsultationsForm from 'pages/initialConsultations/components/initialConsultationForm/InitialConsultationsForm';
import VirtualFollowupsForm from 'pages/settings/pages/virtualFollowups/components/virtualFollowupForm/VirtualFollowupsForm';

export default function InvoicesPage({ isSingle }: { isSingle?: boolean }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [processingId, setProcessingId] = useState('');
  const { form_id, form_type } = useParams();

  const [invoicesFilters, setInvoicesFilters] = useState({
    first_name: '',
    last_name: '',
    email: '',
    status: '',
    date_created: null
  });
  const [sortingDetails, setSortingDetails] = useState({
    sort_by: 'created_at',
    sort_order: 'desc'
  });

  const { data: invoicesData, isLoading } = useInvoices(
    currentPage,
    invoicesFilters,
    sortingDetails
  );
  const { mutateAsync: automateInvoice, isPending: isAutomationPending } =
    useAutomateInvoice();

  const packages = useMemo(() => {
    return invoicesData?.invoices ?? [];
  }, [invoicesData?.invoices]);

  return (
    <div className='p-5 bg-[#FAFBFC]'>
      <div className='border border-light-grey bg-white rounded-xl p-2.5'>
        <div className='flex justify-between mb-3'>
          <h1 className='text-xl font-semibold mb-2'>Invoices</h1>
          <HeaderActionButtons />
        </div>
        <Filters
          setInvoicesFilters={setInvoicesFilters}
          setCurrentPage={setCurrentPage}
          invoicesFilters={invoicesFilters}
        />
        <div className='overflow-auto h-[calc(100vh_-_320px)]'>
          <DataTable
            isLoading={isLoading}
            data={packages}
            columns={Columns(
              automateInvoice,
              isAutomationPending,
              setProcessingId,
              processingId,
              setSortingDetails,
              sortingDetails
            )}
          />
        </div>
      </div>
      <Pagination
        isLoading={isLoading}
        totalCount={invoicesData?.pagination.total_count}
        totalPage={invoicesData?.pagination.total_pages}
        perPage={invoicesData?.pagination.per_page}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
      <Drawer
        styles={{ header: { display: 'flex', flexDirection: 'row-reverse' } }}
        width='90%'
        open={Boolean(isSingle)}
        button={null}
        closeIcon={null}
      >
        {form_type === 'ic' ? (
          <InitialConsultationsForm
            isSingle={isSingle}
            formId={String(form_id)}
            prevUrl={paths.invoices}
          />
        ) : (
          <VirtualFollowupsForm
            isSingle={Boolean(isSingle)}
            formId={String(form_id)}
            prevUrl={paths.invoices}
          />
        )}
      </Drawer>
    </div>
  );
}
