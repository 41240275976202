import { FaSort } from 'react-icons/fa';
import formatDate from 'utils/helpers/date';
import { ColumnDef } from '@tanstack/react-table';
import { ICReportsResponse } from 'types/ICReports';
import { Link } from 'react-router-dom';
import paths from 'constants/path';

export const Columns = (
  sortingDetails: any,
  setSortingDetails: any
): ColumnDef<ICReportsResponse>[] => [
  {
    header: () => (
      <div className='flex items-center'>
        <span className='px-1 text-nowrap'>Reviewed At</span>
        <FaSort
          className='cursor-pointer'
          onClick={() =>
            setSortingDetails({
              sort_by: 'reviewed_at',
              sort_order:
                sortingDetails?.sort_by === 'reviewed_at' &&
                sortingDetails?.sort_order === 'desc'
                  ? 'asc'
                  : 'desc'
            })
          }
        />
      </div>
    ),
    accessorKey: 'reviewed_at',
    cell: (info) =>
      info.getValue() && info.getValue() !== 'N/A'
        ? formatDate(info.getValue<string>())
        : 'Not Available'
  },
  {
    header: 'Name',
    accessorKey: 'name',
    cell: (info) => (
      <a
        href={`https://my.practicebetter.io/#/p/clients/${info.row.original.client_record_id}`}
        rel='noopener noreferrer'
        target='_blank'
        className='flex items-center gap-2'
      >
        <p className='underline text-orange underline-offset-4'>
          {info.getValue<string>()}
        </p>
      </a>
    )
  },
  {
    header: 'Email',
    accessorKey: 'email',
    cell: (info) => (
      <Link
        to={paths.customer(String(info.row.original.client_record_id))}
        className='text-orange underline underline-offset-4'
      >
        {info.getValue<string>()}
      </Link>
    )
  },
  {
    header: () => (
      <div className='flex items-center'>
        <span className='px-1 text-nowrap'>Form Completed At</span>
        <FaSort
          className='cursor-pointer'
          onClick={() =>
            setSortingDetails({
              sort_by: 'created_at',
              sort_order:
                sortingDetails?.sort_by === 'created_at' &&
                sortingDetails?.sort_order === 'desc'
                  ? 'asc'
                  : 'desc'
            })
          }
        />
      </div>
    ),
    accessorKey: 'completed_at',
    cell: (info) => formatDate(info.getValue<string>())
  },
  {
    header: 'Consultant',
    accessorKey: 'consultant'
  },
  {
    header: 'AI Processed',
    accessorKey: 'ai_processed',
    cell: (info) => (info.row.original.ai_processed ? 'Yes' : 'No')
  },
  // {
  //   header: 'Has Session Notes',
  //   accessorKey: ' has_session_note ',
  //   cell: (info) => (info.row.original.has_session_note ? 'Yes' : 'No')
  // },
  // {
  //   header: 'Completed',
  //   accessorKey: 'completed',
  //   cell: (info) => (info.row.original.completed ? 'Yes' : 'No')
  // },
  {
    header: 'Submitted',
    accessorKey: 'status',
    cell: (info) => (info.row.original.status ? 'Yes' : 'No')
  },
  {
    header: 'Has Invoice',
    accessorKey: 'invoice',
    cell: (info) => (info.row.original.invoice ? 'Yes' : 'No')
  },
  {
    header: 'Has Invoice Paid',
    accessorKey: 'invoice_paid',
    cell: (info) => (info.row.original.invoice_paid ? 'Yes' : 'No')
  },
  {
    header: 'Has Package',
    accessorKey: 'has_package',
    cell: (info) => (info.row.original.has_package ? 'Yes' : 'No')
  },
  {
    header: 'Has Payment Plan',
    accessorKey: 'has_pp',
    cell: (info) => (info.row.original.has_pp ? 'Yes' : 'No')
  },
  {
    header: 'Has Sessions',
    accessorKey: 'has_sessions',
    cell: (info) => (info.row.original.has_sessions ? 'Yes' : 'No')
  },
  {
    header: 'Has Order',
    accessorKey: 'has_order',
    cell: (info) => (info.row.original.has_order ? 'Yes' : 'No')
  }
];
