import React from 'react';
import { ConfigProvider, Select as SelectComponent, SelectProps } from 'antd';

interface CustomSelectProps extends SelectProps {
  data?: Array<{ [key: string]: any }>;
  labelKey?: string;
  valueKey?: string;
}

export default function Select({
  data,
  labelKey = 'label',
  valueKey = 'value',
  options = [],
  placeholder,
  ...props
}: CustomSelectProps) {
  const mappedOptions = data
    ? data.map((item) => ({
        label: item[labelKey],
        value: item[valueKey]
      }))
    : options;

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: 'rgb(252 103 32)'
        }
      }}
    >
      <SelectComponent
        placeholder={placeholder}
        options={mappedOptions}
        {...props}
      />
    </ConfigProvider>
  );
}
