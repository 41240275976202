/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import DataTable from 'components/dataTable/DataTable';
import Pagination from 'components/pagination/Pagination';
import Search from 'components/search/Search';
import Select from 'components/select/Select';
import { useMemo, useState } from 'react';
import { request } from 'utils/api';
import { useDebounce } from 'use-debounce';
import Button from 'components/buttons/Button';
import { DeleteFilled, EditFilled, PlusOutlined } from '@ant-design/icons';
import paths from 'constants/path';
import { Link } from 'react-router-dom';

const getIntents = async (currentPage: number) => {
  const data = await request({
    url: '/intents',
    method: 'GET',
    params: { page: currentPage }
  });
  return data;
};

const deleteIntent = async (id: string) => {
  const data = await request({
    url: `/intents/${id}`,
    method: 'DELETE'
  });
  return data;
};

export default function IntentsPage() {
  const queryClient = useQueryClient();

  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [debounceSearchText] = useDebounce(searchText, 1000);

  const { data: intentsData, isLoading } = useQuery({
    queryKey: ['intents', currentPage, debounceSearchText],
    queryFn: () => getIntents(currentPage)
  });

  const { mutateAsync: deleteIntentRecord } = useMutation({
    mutationFn: (id: string) => deleteIntent(id),
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['intents'] });
    }
  });

  const columns = [
    {
      header: 'Intent',
      accessorKey: 'category'
    },
    {
      header: 'Category',
      accessorKey: 'question'
    },
    {
      header: 'Actions',
      accessorKey: 'id',
      cell: (info: any) => (
        <div className='flex gap-4 items-center'>
          <EditFilled className='text-lg w-6 h-6 text-orange' />
          <DeleteFilled
            onClick={() => deleteIntentRecord(info.row.original.id)}
            className='text-lg w-6 h-6 text-red cursor-pointer'
          />
        </div>
      )
    }
  ];

  const intentsMemorizedData = useMemo(() => {
    return intentsData?.intents ?? [];
  }, [intentsData?.intents]);

  return (
    <h1 className='font-bold text-center text-silver text-3xl mt-10'>
      Coming Soon...
    </h1>
  );

  // return (
  //   <div className='p-8 bg-[#FAFBFC]'>
  //     <div className='flex justify-between items-center'>
  //       <div className='flex gap-6 items-center'>
  //         <Select
  //           placeholder='Category'
  //           options={[
  //             { value: 'general', label: 'General' },
  //             { value: 'pricing', label: 'Pricing' },
  //             { value: 'all', label: 'All' }
  //           ]}
  //           className='w-[129px]'
  //         />
  //         <Search
  //           placeholder='Search'
  //           type='text'
  //           value={searchText}
  //           onChange={(e) => setSearchText(e.target.value)}
  //         />
  //       </div>
  //       <Link to={paths.addKnowledgeBase}>
  //         <Button className='!w-fit px-4'>
  //           <PlusOutlined className='mr-2' />
  //           Add Prompt
  //         </Button>
  //       </Link>
  //     </div>
  //     <div className='border border-light-grey rounded-xl p-8 mt-6 bg-white'>
  //       <h5 className='text-xl font-semibold mb-5'>Order_Related</h5>
  //       <DataTable
  //         isLoading={isLoading}
  //         data={intentsMemorizedData}
  //         columns={columns}
  //         isVerticalBorderEnabled={true}
  //       />
  //     </div>
  //     <Pagination
  //       isLoading={isLoading}
  //       totalCount={intentsData?.pagination.total_count}
  //       totalPage={intentsData?.pagination.total_pages}
  //       perPage={intentsData?.pagination.per_page}
  //       currentPage={currentPage}
  //       setCurrentPage={setCurrentPage}
  //     />
  //   </div>
  // );
}
