import React from 'react';
import { Spin } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import { useSyncInvoices } from '../api/useApi';

const HeaderActionButtons = () => {
  const { isLoading: isSyncPending, refetch: syncAllInvoices } =
    useSyncInvoices();

  return (
    <div>
      <button
        disabled={isSyncPending}
        className='border border-1 border-orange px-2 py-1 bg-orange text-white rounded-lg w-28 disabled:opacity-50 disabled:cursor-not-allowed'
        onClick={() => syncAllInvoices()}
      >
        {isSyncPending ? (
          <Spin />
        ) : (
          <>
            <SyncOutlined className='mr-1' />
            Sync
          </>
        )}
      </button>
    </div>
  );
};

export default HeaderActionButtons;
