import {
  InitialConsultationFormInputs,
  InitialConsultationsFilter
} from 'types/initialConsultations';
import {
  useQuery,
  useMutation,
  useQueryClient,
  UseMutationResult,
  UseQueryResult
} from '@tanstack/react-query';
import {
  getDropdownValues,
  getInitialConsultation,
  getInitialConsultations,
  handleArchiveData,
  handleRefreshAIPrompts,
  handleSendTaskMessage,
  handleSessionNotes,
  handleUpdateInitialConsultations,
  handleUpdateReviewedICs,
  syncInitialConsultations
} from '.';

// Custom hook for fetching initial consultations
export const useInitialConsultations = (
  currentPage: number,
  initialConsultationFilters: InitialConsultationsFilter,
  sortingDetails: object
) => {
  return useQuery({
    queryKey: [
      'initial_consultations',
      currentPage,
      initialConsultationFilters,
      sortingDetails
    ],
    queryFn: () =>
      getInitialConsultations(
        currentPage,
        initialConsultationFilters,
        sortingDetails
      )
  });
};

// Custom hook for fetching a single initial consultation
export const useInitialConsultation = (
  id: string | undefined,
  isSingle: boolean
) => {
  return useQuery({
    queryKey: ['initial_consultation', id],
    queryFn: () => getInitialConsultation(String(id)),
    enabled: Boolean(id && isSingle)
  });
};

// Custom hook for syncing initial consultations
export const useSyncInitialConsultations = () => {
  return useQuery({
    queryKey: ['sync_initial_consultations'],
    queryFn: () => syncInitialConsultations('6729169fd97dc2e0e3624318'),
    enabled: false
  });
};

// Custom hook for archiving data
export const useArchiveData = (option: { onSuccess: () => void }) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (ids: string[]) => handleArchiveData(ids),
    onSuccess: () => {
      option?.onSuccess && option.onSuccess();
      queryClient.invalidateQueries({
        queryKey: ['initial_consultations_archive']
      });
      queryClient.refetchQueries({ queryKey: ['initial_consultations'] });
    }
  });
};

// Custom hook for sending task message email
export const useSendTaskMessageEmail = (option: { onSuccess: () => void }) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: { id: string; formData: any }) =>
      handleSendTaskMessage(
        { task_message: data.formData.task_message },
        data.id
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['send_email'] });
      queryClient.refetchQueries({ queryKey: ['send_email'] });
      option?.onSuccess && option.onSuccess();
    }
  });
};

// Hook for updating initial consultations
export const useUpdateInitialConsultations = (options?: {
  onSuccess?: (data: any) => void;
  id?: String;
}): UseMutationResult<any, unknown, InitialConsultationFormInputs, unknown> => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (data: InitialConsultationFormInputs) =>
      handleUpdateInitialConsultations(data, String(options?.id)),
    onSuccess(data) {
      if (data !== undefined) {
        queryClient.invalidateQueries({ queryKey: ['initial_consultations'] });
        queryClient.refetchQueries({ queryKey: ['initial_consultations'] });
        options?.onSuccess?.(data);
      }
    }
  });
  return mutation;
};

export const useUpdateReviewedICs = (options?: {
  onSuccess?: (data: any) => void;
  id?: String;
}): UseMutationResult<any, unknown, InitialConsultationFormInputs, unknown> => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (data: InitialConsultationFormInputs) =>
      handleUpdateReviewedICs(data, String(options?.id)),
    onSuccess(data) {
      if (data !== undefined) {
        queryClient.invalidateQueries({ queryKey: ['initial_consultations'] });
        queryClient.refetchQueries({ queryKey: ['initial_consultations'] });
        options?.onSuccess?.(data);
      }
    }
  });
  return mutation;
};

// Hook for creating session notes
export const useCreateSessionNotes = (options?: {
  onSuccess?: (data: any, type?: string) => void;
  id: String;
}): UseMutationResult<any, unknown, { status?: string }, unknown> => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: (data: { status?: string }) =>
      handleSessionNotes(data, String(options?.id)),
    onSuccess(data) {
      if (data !== undefined) {
        queryClient.invalidateQueries({ queryKey: ['initial_consultations'] });
        queryClient.refetchQueries({ queryKey: ['initial_consultations'] });
        options?.onSuccess?.(data, 'sessionCreated');
      }
    }
  });

  return mutation;
};

// Hook for refreshing AI prompts
export const useRefreshAIPrompts = (options?: {
  onSuccess?: (data: any, type?: string) => void;
  id: String;
}): UseMutationResult<any, unknown, string, unknown> => {
  const mutation = useMutation({
    mutationFn: (columnName: string) =>
      handleRefreshAIPrompts(columnName, String(options?.id)),
    onSuccess(data) {
      if (data !== undefined) {
        options?.onSuccess?.(data, 'promptRefreshed');
      }
    }
  });

  return mutation;
};

export const useDropdownValues = (
  id?: string,
  preferred_plan?: string,
  discount?: string
): UseQueryResult<any, unknown> => {
  return useQuery({
    queryKey: ['dropdown_values', id, preferred_plan, discount],
    queryFn: () => getDropdownValues(String(id)),
    enabled: Boolean(id && id !== 'undefined')
  });
};
