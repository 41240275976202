import React from 'react';
import PackageForm from './PackageForm';
import Drawer from 'components/drawer/Drawer';
import { PackagesResponse } from 'types/packages';
import { ColumnDef } from '@tanstack/react-table';
import { ArrowRightOutlined, EditOutlined } from '@ant-design/icons';

export const Columns = (): ColumnDef<PackagesResponse>[] => [
  {
    header: 'Name',
    accessorKey: 'pb_package_name',
    cell: (info) =>
      info
        .getValue<Array<{ name: string; selected: boolean }>>()
        .filter((item) => item.selected)
        .map((item) => item.name) || 'N/A'
  },
  {
    header: 'PB Id',
    accessorKey: 'pb_package_id',
    cell: (info) => (
      <a
        href={`https://my.practicebetter.io/#/p/packages/${info.row.original.pb_package_id}/edit`}
        rel='noopener noreferrer'
        target='_blank'
        className='flex items-center gap-2'
      >
        <p className='underline text-orange underline-offset-4'>
          {info.getValue<string>()}
        </p>
      </a>
    )
  },
  {
    header: 'Medication',
    accessorKey: 'medication'
  },
  {
    header: 'Invoice Amount',
    accessorKey: 'invoice_amount',
    cell: (info) => <span>${info.getValue<string>()}</span>
  },
  {
    header: 'Plan',
    accessorKey: 'plan'
  },
  {
    header: 'Payment Plan Duration',
    accessorKey: 'payment_plan_duration',
    cell: (info) => <span>{info.getValue<string>()} Months</span>
  },
  {
    header: 'Payment Plan Amount',
    accessorKey: 'payment_plan_amount',
    cell: (info) => <span>${info.getValue<string>()}</span>
  },
  {
    header: 'Discount',
    accessorKey: 'discount'
  },
  {
    header: 'Discount Tag',
    accessorKey: 'discount_tag'
  },
  {
    header: 'Action',
    accessorKey: '',
    cell: (info) => (
      <div className='flex flex-row gap-2'>
        <Drawer
          styles={{
            header: { display: 'flex', flexDirection: 'row-reverse' }
          }}
          width='50%'
          button={
            <>
              <EditOutlined className='mr-1' />
              Edit
            </>
          }
          title='Edit Package'
          closeIcon={<ArrowRightOutlined />}
        >
          <PackageForm info={info.row.original} />
        </Drawer>
      </div>
    )
  }
];
