import { Input, Spin } from 'antd';
import { Link } from 'react-router-dom';
import Radio from 'components/radio/Radio';
import formatDate from 'utils/helpers/date';
import Select from 'components/select/Select';
import { Label } from 'components/label/Label';
import ICPackageDetails from './ICPackageDetails';
import { FaExternalLinkAlt } from 'react-icons/fa';
import FormFooterButtons from './FormFooterButtons';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { medicationOptions } from 'constants/medicationOptions';
import WysiwygEditor from 'components/wysiwygEditor/WysiwygEditor';
import { getDoseValueOptions } from 'utils/helpers/medicationDoseOptions';
import {
  ArrowRightOutlined,
  EditOutlined,
  RedoOutlined
} from '@ant-design/icons';
import {
  InitialConsultationFormInputs,
  InitialConsultationsResponse
} from 'types/initialConsultations';
import {
  useCreateSessionNotes,
  useDropdownValues,
  useInitialConsultation,
  useRefreshAIPrompts,
  useUpdateInitialConsultations,
  useUpdateReviewedICs
} from 'pages/initialConsultations/api/useApi';

type Item = {
  name: string;
  selected: boolean;
};

export default function InitialConsultationsForm({
  isSingle,
  prevUrl,
  formId
}: {
  isSingle?: boolean;
  prevUrl: string;
  formId: string;
}) {
  const { data: InitialConsultationData, isPending: isFormLoading } =
    useInitialConsultation(formId, Boolean(isSingle));

  const initialConsultationMemorizedData = useMemo(() => {
    return InitialConsultationData?.form_request ?? [];
  }, [InitialConsultationData?.form_request]);

  const [formData, setFormData] = useState<
    InitialConsultationsResponse | undefined
  >(initialConsultationMemorizedData);
  const [refreshingColumn, setRefreshingColumn] = useState('');
  const [followUpClicked, setFollowUpClicked] = useState(false);
  const [isEditReviewedForm, setIsEditReviewedForm] = useState(false);

  const { data: dropdownValues, isPending: valuesLoading } = useDropdownValues(
    String(formData?.id),
    formData?.preferred_plan,
    formData?.discount
  );

  useEffect(() => {
    setFormData(initialConsultationMemorizedData);
  }, [initialConsultationMemorizedData]);

  const formInitials = formData?.id
    ? {
        completed_at: formatDate(formData.completed_at),
        medication: formData.medication,
        name: formData.name,
        preferred_medications: formData.preferred_medications,
        preferred_plan: valuesLoading
          ? 'Loading...'
          : getSelectedNames(dropdownValues?.preffered_plan),
        submit: formData.submit,
        weight: formData.weight,
        dose: formData.dose,
        id: formData.id,
        message_to_patient: formData.message_to_patient,
        ic_assessment_and_plan: formData.ic_assessment_and_plan,
        ic_hpi: formData.ic_hpi,
        ic_pmh: formData.ic_pmh,
        discount: valuesLoading
          ? 'Loading...'
          : getSelectedNames(dropdownValues?.discount),
        send_review: formData.send_review,
        plan: formData.plan,
        goals: formData.goals,
        trustpilot_status:
          formData.trustpilot_status === 'Sent'
            ? 'Sending'
            : formData.trustpilot_status
      }
    : {
        completed_at: '',
        medication: '',
        name: '',
        preferred_medications: '',
        preferred_plan: '',
        submit: false,
        weight: '',
        dose: '',
        id: null,
        message_to_patient: '',
        ic_assessment_and_plan: '',
        ic_hpi: '',
        ic_pmh: '',
        discount: '',
        send_review: false,
        plan: '',
        goals: '',
        trustpilot_status: 'Pending'
      };

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { dirtyFields, errors }
  } = useForm<InitialConsultationFormInputs>({
    values: formInitials
  });
  const selectedMedication = watch('medication');

  const updateField = (fieldName: any, value: any) => {
    const aiPromptFields = {
      ic_hpi: watch('ic_hpi'),
      ic_pmh: watch('ic_pmh'),
      message_to_patient: watch('message_to_patient'),
      ic_assessment_and_plan: watch('ic_assessment_and_plan')
    };
    !isEditReviewedForm
      ? updateInitialConsultations({
          ...{ [fieldName]: value },
          ...aiPromptFields
        })
      : setValue(fieldName, value);
  };

  const onSuccess = (data: any, type?: string) => {
    switch (type) {
      case 'promptRefreshed':
        let updatedField = data.payload?.column_name;
        let updatedValue = data?.form_request[updatedField];
        setValue(updatedField, updatedValue);
        return null;
      case 'sessionCreated':
        setFormData(data.form_request);
        setFollowUpClicked(false);
        return null;
      default:
        setFormData(data.form_request);
    }
  };

  const {
    mutateAsync: updateInitialConsultations,
    isPending: isUpdatePending
  } = useUpdateInitialConsultations({
    onSuccess: onSuccess,
    id: String(formData?.id)
  });

  const { mutateAsync: updateReviewedICs, isPending: updatingReviewedIC } =
    useUpdateReviewedICs({
      onSuccess: onSuccess,
      id: String(formData?.id)
    });

  const { mutateAsync: createSessionNotes, isPending: isCreationPending } =
    useCreateSessionNotes({
      onSuccess: onSuccess,
      id: String(formData?.id)
    });

  const { mutateAsync: refreshAIPrompts, isPending: isRefreshPending } =
    useRefreshAIPrompts({
      onSuccess: onSuccess,
      id: String(formData?.id)
    });

  const onSubmit = async (data: InitialConsultationFormInputs) => {
    let dirtyData: Partial<InitialConsultationFormInputs> = {};
    Object.keys(data).forEach((key) => {
      const objKey = key as keyof InitialConsultationFormInputs;

      if (dirtyFields[objKey]) {
        const value = data[objKey];
        if (typeof value === 'string' || value === undefined) {
          dirtyData[objKey] = value;
        } else if (value !== null) {
          dirtyData[objKey] = String(value);
        }
      }
    });
    if (formData) {
      dirtyData = followUpClicked
        ? { ...dirtyData, status: 'Follow-up' }
        : { ...dirtyData, status: 'Reviewed' };
      if (isEditReviewedForm) {
        await updateReviewedICs({ ...data, status: 'Reviewed' });
        setIsEditReviewedForm(false);
      } else {
        await updateInitialConsultations(dirtyData);
        createSessionNotes({ status: dirtyData.status });
      }
    }
  };

  const isPending = isUpdatePending || updatingReviewedIC;
  const isFormLocked =
    (formData?.status === 'Reviewed' && !isEditReviewedForm) || isFormLoading;

  function getSelectedNames(data: Item[]): string | undefined {
    if (data) {
      return data
        .filter((item) => item.selected)
        .map((item) => item.name)
        .join(', ');
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='flex flex-row'>
        <div className='w-1/2 border-r'>
          {isFormLoading ? (
            <span>Loading details...</span>
          ) : (
            <div className='flex flex-row justify-between text-base font-semibold mr-9 pb-2 border-b'>
              <div className='flex  gap-2 '>
                <span>Patient Name -</span>
                <a
                  href={`https://my.practicebetter.io/#/p/clients/${formData?.client_record_id}`}
                  rel='noopener noreferrer'
                  target='_blank'
                  className='flex items-center gap-2'
                >
                  <p className='text-orange'>{formData?.name}</p>
                </a>
              </div>
              {!isEditReviewedForm && formData?.status === 'Reviewed' && (
                <button
                  className='text-orange mx-1'
                  onClick={() => setIsEditReviewedForm(true)}
                >
                  <EditOutlined className='mr-1' />
                  Edit
                </button>
              )}
            </div>
          )}
          <div className={`m-3 ml-0 ${isFormLocked && 'opacity-50'}`}>
            <div className='mb-6'>
              <Label
                className={`!font-normal mb-1.5 ${isFormLocked && 'pointer-events-none'}`}
              >
                <span className='flex flex-row'>
                  <span>HPI</span>
                  {isRefreshPending && refreshingColumn === 'ic_hpi' ? (
                    <span className='text-orange px-2 text-sm mt-[3px]'>
                      Refreshing...
                    </span>
                  ) : (
                    <RedoOutlined
                      className='text-orange px-1 rotate-180 cursor-pointer mt-[3px]'
                      onClick={() => {
                        refreshAIPrompts('ic_hpi');
                        setRefreshingColumn('ic_hpi');
                      }}
                    />
                  )}
                </span>
              </Label>
              <Controller
                name='ic_hpi'
                control={control}
                render={({ field }) => (
                  <WysiwygEditor readOnly={isFormLocked} {...field} />
                )}
              />
            </div>
            <div className='mb-6'>
              <Label
                className={`!font-normal mb-1.5 ${isFormLocked && 'pointer-events-none'}`}
              >
                <span className='flex flex-row'>
                  <span>PMH</span>
                  {isRefreshPending && refreshingColumn === 'ic_pmh' ? (
                    <span className='text-orange px-2 text-sm mt-[3px]'>
                      Refreshing...
                    </span>
                  ) : (
                    <RedoOutlined
                      className='text-orange px-1 rotate-180 cursor-pointer mt-[3px]'
                      onClick={() => {
                        refreshAIPrompts('ic_pmh');
                        setRefreshingColumn('ic_pmh');
                      }}
                    />
                  )}
                </span>
              </Label>
              <Controller
                name='ic_pmh'
                control={control}
                render={({ field }) => (
                  <WysiwygEditor readOnly={isFormLocked} {...field} />
                )}
              />
            </div>
          </div>
        </div>
        <div className='w-1/2 pl-4'>
          <div className='border-b flex flex-row justify-between'>
            <span className='text-base font-semibold pb-2'>
              {isFormLocked ? <>Details</> : 'Edit Details'}
            </span>
            <Link to={String(prevUrl)}>
              <ArrowRightOutlined
                className='text-lg pr-4 pb-3'
                onClick={() => setIsEditReviewedForm(false)}
              />
            </Link>
          </div>
          <div className={`mt-3.5 gap-6 mb-20 ${isFormLocked && 'opacity-50'}`}>
            <div
              className={`grid grid-cols-3 mb-7 ${formData?.status === 'Reviewed' && 'pointer-events-none opacity-50'}`}
            >
              <div className='mr-6'>
                <Label className='!font-normal'>Preferred Medication</Label>
                <Controller
                  disabled
                  name='preferred_medications'
                  control={control}
                  render={({ field }) => (
                    <Input
                      type='text'
                      className=' mt-2 w-full h-[30px]'
                      {...field}
                    />
                  )}
                />
              </div>
              <div className='mr-4'>
                <Label className='!font-normal mb-1.5'>Plan</Label>
                <Controller
                  name='preferred_plan'
                  disabled={valuesLoading}
                  control={control}
                  render={({ field }) => (
                    <Select
                      placeholder='Select Plan'
                      {...field}
                      className='w-full h-[30px]'
                      options={[
                        { value: 'Month to month', label: 'Month To Month' },
                        { value: '3 month', label: '3 Months' },
                        { value: '9 month', label: '9 Months' }
                      ]}
                      onChange={(val) => {
                        updateField('preferred_plan', val);
                      }}
                    />
                  )}
                />
              </div>
              <div className='mx-4'>
                <Label className='!font-normal mb-1.5'>Goals</Label>
                <Controller
                  name='goals'
                  control={control}
                  render={({ field }) => (
                    <Select
                      placeholder='Select Goal'
                      {...field}
                      className='w-full h-[30px]'
                      options={[
                        { value: 'Maintenance', label: 'Maintenance' },
                        { value: 'Escalation', label: 'Escalation' }
                      ]}
                      onChange={(val) => {
                        updateField('goals', val);
                      }}
                    />
                  )}
                />
              </div>
            </div>
            <div
              className={`${formData?.status === 'Reviewed' && 'pointer-events-none opacity-50'}`}
            >
              <div className='mt-3.5'>
                <Label className='!font-normal mb-1.5'>Discount</Label>
                <Controller
                  name='discount'
                  control={control}
                  disabled={valuesLoading}
                  render={({ field }) => (
                    <Select
                      placeholder='Select Discount'
                      {...field}
                      className='w-full h-[30px]'
                      data={dropdownValues?.discount}
                      labelKey='name'
                      valueKey='name'
                      onChange={(val) => {
                        updateField('discount', val);
                      }}
                    />
                  )}
                />
              </div>
            </div>
            {formData?.package && (
              <ICPackageDetails
                getSelectedNames={getSelectedNames}
                currentPackage={formData?.package}
              />
            )}
            <div
              className={`grid grid-cols-3 mb-6 mt-3.5 ${isFormLocked && 'cursor-not-allowed'}`}
            >
              <div className='w-[8rem]'>
                <Label className='!font-normal'>Order Medication</Label>
                <Controller
                  name='medication'
                  control={control}
                  render={({ field }) => (
                    <Radio
                      className='w-full mt-2 h-[38px]'
                      {...field}
                      options={[
                        ...medicationOptions,
                        { value: 'No Order', label: 'No Order' }
                      ]}
                      onChange={(e) => {
                        updateField('medication', e?.target?.value);
                      }}
                    />
                  )}
                />
                <p className='text-red text-sm'>
                  {errors?.medication?.message}
                </p>
              </div>
              {selectedMedication !== 'No Order' && (
                <div className='w-[25rem]'>
                  <Label className='!font-normal'>Dose</Label>
                  <Controller
                    name='dose'
                    control={control}
                    render={({ field }) => (
                      <Radio
                        className='w-full mt-2 h-[38px]'
                        {...field}
                        options={getDoseValueOptions(
                          String(selectedMedication)
                        )}
                        onChange={(e) => {
                          updateField('dose', e?.target?.value);
                        }}
                      />
                    )}
                  />
                </div>
              )}
            </div>
            <div
              className={`grid grid-cols-3 mb-6 mt-3.5 ${formData?.status === 'Reviewed' && 'pointer-events-none opacity-50'}`}
            >
              <div>
                <Label className='!font-normal'>Send Review</Label>
                <Controller
                  name='trustpilot_status'
                  control={control}
                  render={({ field }) => (
                    <Radio
                      className='w-full mt-2 h-[38px]'
                      {...field}
                      options={[
                        { value: 'Sending', label: 'Yes' },
                        { value: 'Pending', label: 'No' }
                      ]}
                      onChange={(e) => {
                        updateField('trustpilot_status', e?.target?.value);
                      }}
                    />
                  )}
                />
              </div>
            </div>
            <div className='mb-6'>
              <Label
                className={`!font-normal mb-1.5 ${isFormLocked && 'pointer-events-none'}`}
              >
                <span className='flex flex-row'>
                  <span>Message to Patient</span>
                  {isRefreshPending &&
                  refreshingColumn === 'message_to_patient' ? (
                    <span className='text-orange px-2 text-sm mt-[3px]'>
                      Refreshing...
                    </span>
                  ) : (
                    <RedoOutlined
                      className='text-orange px-1 rotate-180 cursor-pointer mt-[3px]'
                      onClick={() => {
                        refreshAIPrompts('message_to_patient');
                        setRefreshingColumn('message_to_patient');
                      }}
                    />
                  )}
                </span>
              </Label>
              <Controller
                name='message_to_patient'
                control={control}
                render={({ field }) => (
                  <WysiwygEditor readOnly={isFormLocked} {...field} />
                )}
              />
            </div>
            <div>
              <Label
                className={`!font-normal mb-1.5 ${isFormLocked && 'pointer-events-none'}`}
              >
                <span className='flex flex-row'>
                  <span>Assessment & Plan</span>
                  {isRefreshPending &&
                  refreshingColumn === 'ic_assessment_and_plan' ? (
                    <span className='text-orange px-2 text-sm mt-[3px]'>
                      Refreshing...
                    </span>
                  ) : (
                    <RedoOutlined
                      className='text-orange px-1 rotate-180 cursor-pointer mt-[3px]'
                      onClick={() => {
                        refreshAIPrompts('ic_assessment_and_plan');
                        setRefreshingColumn('ic_assessment_and_plan');
                      }}
                    />
                  )}
                </span>
              </Label>
              <Controller
                name='ic_assessment_and_plan'
                control={control}
                render={({ field }) => (
                  <WysiwygEditor readOnly={isFormLocked} {...field} />
                )}
              />
            </div>
          </div>
          {!isFormLocked && !isEditReviewedForm ? (
            <FormFooterButtons
              prevUrl={prevUrl}
              followUpClicked={followUpClicked}
              setFollowUpClicked={setFollowUpClicked}
              isPending={isPending || isCreationPending}
              showSubmitButton={Boolean(
                formData?.package?.id || selectedMedication === 'No Order'
              )}
            />
          ) : (
            <div className='flex gap-5 bg-white justify-end absolute bottom-0 right-0 px-4 py-3 border-t w-full text text-orange h-14 pr-9'>
              {isEditReviewedForm && (
                <button
                  disabled={isPending}
                  type='submit'
                  className='!w-fit px-8 bg-orange text-white rounded-lg font-semibold disabled:cursor-not-allowed w-36'
                >
                  {isPending ? <Spin /> : 'Save'}
                </button>
              )}
              {formData?.order_note_id && (
                <Link
                  className='flex flex-row gap-1 font-semibold mt-1'
                  to={`https://my.practicebetter.io/#/p/clients/${formData?.client_record_id}/notes/${formData?.order_note_id}/edit`}
                  target='_blank'
                >
                  Order Notes
                  <FaExternalLinkAlt className='text-sm cursor-pointer mt-[2.5px]' />
                </Link>
              )}
              {formData?.session_note_id && (
                <Link
                  className='flex flex-row gap-1 font-semibold mt-1'
                  to={`https://my.practicebetter.io/#/p/clients/${formData?.client_record_id}/notes/${formData?.session_note_id}/edit`}
                  target='_blank'
                >
                  Session Notes
                  <FaExternalLinkAlt className='text-sm cursor-pointer mt-[2.5px]' />
                </Link>
              )}
            </div>
          )}
        </div>
      </div>
    </form>
  );
}
