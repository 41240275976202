import { useQuery } from '@tanstack/react-query';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { request } from 'utils/api';
import DataTable from 'components/dataTable/DataTable';
import { Tooltip } from 'antd';
import { ColumnDef } from '@tanstack/react-table';
import { Order } from 'types/order';
import Pagination from 'components/pagination/Pagination';
import Drawer from 'components/drawer/Drawer';
import { ArrowRightOutlined, EditOutlined } from '@ant-design/icons';
import OrderForm from './OrderForm';
import formatDate from 'utils/helpers/date';
import { getStatusColorClasses } from 'utils/helpers/statusColourClasses';

const getOrders = async (currentPage: number, id: string | undefined) => {
  const data = await request({
    url: '/client-orders',
    method: 'GET',
    params: { client_id: id, page: currentPage, per_page: 5 }
  });
  return data;
};

export default function Orders() {
  const [currentPage, setCurrentPage] = useState(1);
  const { id } = useParams();

  const { data: OrdersData, isLoading } = useQuery({
    queryKey: ['orders', id, currentPage],
    queryFn: () => getOrders(currentPage, id)
  });

  const ordersMemorizedData = useMemo(() => {
    return OrdersData?.orders ?? [];
  }, [OrdersData?.orders]);

  return (
    <>
      <div className='border border-light-grey p-4 rounded-xl'>
        <div className='flex justify-between items-center mb-4'>
          <h4 className='text-xl font-semibold'>Order</h4>
          {/* <Drawer
            styles={{
              header: { display: 'flex', flexDirection: 'row-reverse' }
            }}
            width='50%'
            button={
              <Button className='!px-3 text-sm !w-fit'>+ Add Order</Button>
            }
            title='Add Order'
            closeIcon={<ArrowRightOutlined />}
          >
            <OrderForm />
          </Drawer> */}
        </div>
        <div className='overflow-auto h-[calc(100vh_-_400px)]'>
          <DataTable
            data={ordersMemorizedData}
            columns={columns}
            isLoading={isLoading}
          />
        </div>
      </div>
      <Pagination
        totalCount={OrdersData?.pagination.total_count}
        totalPage={OrdersData?.pagination.total_pages}
        perPage={OrdersData?.pagination.per_page}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </>
  );
}

const columns: ColumnDef<Order>[] = [
  {
    header: 'Order Date',
    accessorKey: 'order_date',
    cell: (info) => formatDate(info.getValue() as string)
  },
  {
    header: 'Medication',
    accessorKey: 'medication',
    cell: (info) =>
      info.cell.row.original.medication ? (
        <p className='border border-blue rounded-full py-1 px-2 text-xs text-blue bg-light-blue text-center'>
          {info.getValue() as string}
        </p>
      ) : (
        '---'
      )
  },

  {
    header: 'Tracking No.',
    accessorKey: 'tracking_number',
    cell: (info) =>
      info.cell.row.original.tracking_number ? (
        <p className='text-orange underline truncate max-w-24'>
          <Tooltip title={info.getValue() as string}>
            {info.getValue() as string}
          </Tooltip>
        </p>
      ) : (
        <p className='text-light-grey'>In Process</p>
      )
  },
  {
    header: 'Pharmacy',
    accessorKey: 'pharmacy',
    cell: (info) => info.getValue()
  },
  {
    header: 'Vial Size (mg)',
    accessorKey: 'vial_size'
  },
  {
    header: 'Initial Dose (mg/week)',
    accessorKey: 'initial_dose'
  },
  {
    header: 'Units',
    accessorKey: 'units',
    cell: (info) => info.getValue()
  },
  {
    header: 'Order Created Email Sent',
    accessorKey: 'order_created_email_sent',
    cell: (info) => ((info.getValue() as string) ? 'Yes' : 'No')
  },
  {
    header: 'Tracking Sms Sent',
    accessorKey: 'sms_sent',
    cell: (info) => ((info.getValue() as string) ? 'Yes' : 'No')
  },
  {
    header: 'Order Tracking Email Sent',
    accessorKey: 'order_tracking_email_sent',
    cell: (info) => ((info.getValue() as string) ? 'Yes' : 'No')
  },
  {
    header: 'Type',
    accessorKey: 'parent_order_id',
    cell: (info) => (info.getValue() ? 'Child' : 'Parent')
  },
  {
    header: 'Added to sheet',
    accessorKey: 'added_to_sheet',
    cell: (info) => (info.getValue() ? 'Yes' : 'No')
  },
  {
    header: 'Status',
    accessorKey: 'ups_status',
    cell: (info) =>
      info.getValue() && (
        <p
          className={`border rounded-full py-1 px-2 text-xs text-center w-[8rem] ${getStatusColorClasses(info.getValue() as string)}`}
        >
          {info.getValue() as string}
        </p>
      )
  },
  {
    header: '',
    accessorKey: 'id',
    cell: (info) => (
      <>
        <Drawer
          styles={{ header: { display: 'flex', flexDirection: 'row-reverse' } }}
          width='50%'
          button={
            <>
              <EditOutlined className='mr-1' />
              Edit
            </>
          }
          title='Edit Order Details'
          closeIcon={<ArrowRightOutlined />}
        >
          <OrderForm info={info.row.original} />
        </Drawer>
      </>
    )
  }
];
