import { useState } from 'react';
import Input from 'components/input/Input';
import { UsersFormInputs } from 'types/users';
import Select from 'components/select/Select';
import Button from 'components/buttons/Button';
import { Label } from 'components/label/Label';
import { useUpdateUser } from '../../api/useApi';
import { Controller, useForm } from 'react-hook-form';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';

export default function UsersForm({ info, setIsClose }: any) {
  const [viewPassword, setViewPassword] = useState(false);
  const [viewConfirmPassword, setViewConfirmPassword] = useState(false);
  const [isUpdatePassword, setIsUpdatePassword] = useState(false);

  const formInitials = {
    first_name: info.first_name,
    last_name: info.last_name,
    email: info.email,
    role: info.role
  };

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors, dirtyFields }
  } = useForm<UsersFormInputs>({
    values: formInitials
  });

  const handleSuccess = () => {
    setIsClose();
    reset();
    setIsUpdatePassword(false);
  };
  const password = watch('password');

  const { mutateAsync: updateUser, isPending: isUpdatePending } = useUpdateUser(
    info.id,
    { onSuccess: handleSuccess }
  );

  const onSubmit = (data: any) => {
    const dirtyData: UsersFormInputs = {};
    Object.keys(data).forEach((key) => {
      const objKey = key as keyof UsersFormInputs;
      if (dirtyFields[objKey]) {
        dirtyData[objKey] = data[objKey];
      }
    });
    updateUser(dirtyData);
  };

  return (
    <>
      <p className='text-base font-semibold'>User Details</p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='grid grid-cols-2 mt-3.5 gap-6'>
          <div>
            <Label className='!font-normal mb-2'>First Name</Label>
            <Controller
              name='first_name'
              control={control}
              render={({ field }) => (
                <Input
                  placeholder='First Name'
                  type='text'
                  className='h-[38px]'
                  {...field}
                />
              )}
            />
          </div>
          <div>
            <Label className='!font-normal mb-2'>Last Name</Label>
            <Controller
              name='last_name'
              control={control}
              render={({ field }) => (
                <Input
                  placeholder='last Name'
                  type='text'
                  className='h-[38px]'
                  {...field}
                />
              )}
            />
          </div>
          <div>
            <Label className='!font-normal mb-2'>Email</Label>
            <Controller
              name='email'
              control={control}
              render={({ field }) => (
                <Input
                  placeholder='Email'
                  type='text'
                  className='h-[38px]'
                  {...field}
                />
              )}
            />
          </div>
          <div>
            <Label className='!font-normal'>Role</Label>
            <Controller
              name='role'
              control={control}
              render={({ field }) => (
                <Select
                  className='w-full mt-2 h-[38px]'
                  {...field}
                  placeholder='Select'
                  options={[
                    { value: 'Super Admin', label: 'Super Admin' },
                    { value: 'Practitioner', label: 'Practitioner' }
                  ]}
                />
              )}
            />
          </div>
          {isUpdatePassword ? (
            <>
              <div>
                <Label className='!font-normal mb-2'>
                  New Password
                  {viewPassword ? (
                    <EyeInvisibleOutlined
                      className='mx-1 text-orange'
                      onClick={() => setViewPassword(false)}
                    />
                  ) : (
                    <EyeOutlined
                      className='mx-1 text-orange'
                      onClick={() => setViewPassword(true)}
                    />
                  )}
                </Label>
                <Controller
                  name='password'
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder='Password'
                      type={viewPassword ? 'text' : 'password'}
                      className='h-[38px]'
                      {...field}
                    />
                  )}
                />
              </div>
              <div>
                <Label className='!font-normal mb-2'>
                  Confirm Password
                  {viewConfirmPassword ? (
                    <EyeInvisibleOutlined
                      className='mx-1 text-orange'
                      onClick={() => setViewConfirmPassword(false)}
                    />
                  ) : (
                    <EyeOutlined
                      className='mx-1 text-orange'
                      onClick={() => setViewConfirmPassword(true)}
                    />
                  )}
                </Label>
                <Controller
                  name='confirm_password'
                  control={control}
                  rules={{
                    validate: (value) =>
                      password
                        ? value === password || 'Passwords must match'
                        : true
                  }}
                  render={({ field }) => (
                    <Input
                      placeholder='Password'
                      type={viewConfirmPassword ? 'text' : 'password'}
                      className='h-[38px]'
                      {...field}
                    />
                  )}
                />
                {errors.confirm_password && (
                  <p className='text-red-500 text-sm'>
                    {errors.confirm_password.message}
                  </p>
                )}
              </div>
            </>
          ) : (
            <span
              className='text-orange font-medium mx-3 underline cursor-pointer'
              onClick={() => setIsUpdatePassword(true)}
            >
              Update Password
            </span>
          )}
        </div>
        <div className='flex gap-5 bg-white justify-end absolute bottom-0 right-0 px-4 py-3 border-t w-full'>
          <Button className='!w-fit py-3 px-8' isLoading={isUpdatePending}>
            Submit
          </Button>
        </div>
      </form>
    </>
  );
}
