import { FollowupsFilter } from 'types/follow-ups';
import { request } from 'utils/api';

export const getPbSessions = async (
  currentPage: number,
  params?: FollowupsFilter,
  sortingDetails?: any,
  id?: string
) => {
  const data = await request({
    url: '/pb-sessions',
    method: 'GET',
    params: { client_id: id, page: currentPage, ...params, ...sortingDetails }
  });
  return data;
};
