import React from 'react';
import dayjs from 'dayjs';
import Input from 'components/input/Input';
import Select from 'components/select/Select';
import Button from 'components/buttons/Button';
import { SearchOutlined } from '@ant-design/icons';
import { MdOutlineFilterAltOff } from 'react-icons/md';
import RangePicker from 'components/rangePicker/RangePicker';
import { VirtualFollowupsFilter } from 'types/virtualFollowups';
import { useUsers } from 'pages/settings/pages/users/api/useApi';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Label } from 'components/label/Label';

interface FiltersProps {
  setCurrentPage: any;
  role: string | undefined;
  setVirtualFollowupsFilters: any;
  default_pb_consultant_id: string;
  virtualFollowupsFilters: VirtualFollowupsFilter;
}

export default function Filters({
  role,
  setCurrentPage,
  virtualFollowupsFilters,
  setVirtualFollowupsFilters,
  default_pb_consultant_id
}: FiltersProps) {
  const { data: usersData } = useUsers(1, 30);

  const { control, handleSubmit, reset } = useForm<VirtualFollowupsFilter>({
    defaultValues: { ...virtualFollowupsFilters }
  });
  const onSubmit: SubmitHandler<VirtualFollowupsFilter> = (data) => {
    data.session_date_gte = data.session_date?.[0];
    data.session_date_lte = data.session_date?.[1];
    delete data.session_date;
    const updatedData = {
      ...data,
      session_date: data.session_date || []
    };
    setVirtualFollowupsFilters(updatedData);
    setCurrentPage(1);
  };
  return (
    <div className='flex justify-between items-center'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='filters flex gap-6 items-center my-4'>
          <div>
            <Label className='text-sm !font-normal m-1'>First Name</Label>
            <Controller
              name='first_name'
              control={control}
              render={({ field }) => (
                <Input className='h-[2rem]' type='text' {...field} />
              )}
            />
          </div>
          <div>
            <Label className='text-sm !font-normal m-1'>Last Name</Label>
            <Controller
              name='last_name'
              control={control}
              render={({ field }) => (
                <Input className='h-[2rem]' type='text' {...field} />
              )}
            />
          </div>
          <div>
            <Label className='text-sm !font-normal m-1'>Email</Label>
            <Controller
              name='email'
              control={control}
              render={({ field }) => (
                <Input className='h-[2rem]' type='email' {...field} />
              )}
            />
          </div>
          <div>
            <Label className='text-sm !font-normal m-1'>Status</Label>
            <Controller
              name='status'
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  className='w-[150px] h-[2rem]'
                  options={[
                    { label: 'Pending', value: 'Pending' },
                    { label: 'Reviewed', value: 'Reviewed' },
                    { label: 'Archive', value: 'Archive' },
                    { label: 'Form Pending', value: 'Form Pending' },
                    { label: 'All', value: '' }
                  ]}
                />
              )}
            />
          </div>
          {role === 'Super Admin' && (
            <div>
              <Label className='text-sm !font-normal m-1'>Consultant</Label>
              <Controller
                name='consultant_id'
                control={control}
                render={({ field }) => (
                  <Select
                    options={usersData?.users
                      ?.filter(
                        (data: { pb_consultant_id: string }) =>
                          !!data.pb_consultant_id
                      )
                      .map(
                        (data: {
                          pb_consultant_id: string;
                          first_name: string;
                          last_name: string;
                        }) => ({
                          value: data.pb_consultant_id,
                          label: `${data.first_name} ${data.last_name}`
                        })
                      )}
                    className='w-[190px] h-[2rem]'
                    {...field}
                  />
                )}
              />
            </div>
          )}
          <div>
            <Label className='text-sm !font-normal m-1'>Session Date</Label>
            <Controller
              control={control}
              name='session_date'
              render={({ field }) => (
                <RangePicker
                  className='!px-2.5 !py-1.5 h-[2rem]'
                  format='MM/DD/YYYY'
                  placeholder={['Start Date', 'End Date']}
                  {...field}
                  onChange={(_, dateStrings: [string, string]) => {
                    console.log(dateStrings);
                    field.onChange(dateStrings);
                  }}
                  value={
                    field.value
                      ? [
                          field.value[0] ? dayjs(field.value[0]) : null,
                          field.value[1] ? dayjs(field.value[1]) : null
                        ]
                      : null
                  }
                />
              )}
            />
          </div>
          <Button className='w-max px-3.5 h-[35px] mt-6'>
            <SearchOutlined className='!text-white' />
          </Button>
          <button
            onClick={() =>
              reset({
                first_name: '',
                last_name: '',
                email: '',
                status: '',
                consultant_id: default_pb_consultant_id,
                session_date: null
              })
            }
            className='border border-orange rounded py-1.5 px-3 mt-6'
          >
            <MdOutlineFilterAltOff className='text-orange' />
          </button>
        </div>
      </form>
    </div>
  );
}
