import React, { useMemo, useState } from 'react';
import { usePackages } from './api/useApi';
import { Columns } from './components/Columns';
import DataTable from 'components/dataTable/DataTable';
import Pagination from 'components/pagination/Pagination';
import HeaderActionButtons from './components/HeaderActionButtons';

export default function Packages() {
  const [currentPage, setCurrentPage] = useState(1);
  const { data: packagesData, isLoading } = usePackages(currentPage);

  const memoizedPackagesData = useMemo(() => {
    return packagesData?.packages ?? [];
  }, [packagesData?.packages]);

  return (
    <div className='p-5 bg-[#FAFBFC]'>
      <div className='border border-light-grey bg-white rounded-xl p-2.5'>
        <div className='flex justify-between my-3'>
          <h1 className='text-xl font-semibold mb-2'>Packages</h1>
          <HeaderActionButtons />
        </div>
        <div className='overflow-auto h-[calc(100vh_-_300px)]'>
          <DataTable
            isLoading={isLoading}
            data={memoizedPackagesData}
            columns={Columns()}
          />
        </div>
      </div>
      <Pagination
        isLoading={isLoading}
        totalCount={packagesData?.pagination.total_count}
        totalPage={packagesData?.pagination.total_pages}
        perPage={packagesData?.pagination.per_page}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
}
