import React, { useState } from 'react';
import { useCreateSession } from '../api/useApi';
import Tooltip from 'components/tooltip/Tooltip';
import { FileDoneOutlined } from '@ant-design/icons';
import PopupConfirm from 'components/popupConfirm/PopupConfirm';

interface ActionColumnProps {
  updateStatus: (ids: string[]) => void;
  info: any;
  handleSuccess: () => void;
}

export default function ActionColumn({
  updateStatus,
  info,
  handleSuccess
}: ActionColumnProps) {
  const [processingId, setProcessingId] = useState('');
  const { mutateAsync: createSession, isPending: isSessionCreationPending } =
    useCreateSession({ onSuccess: handleSuccess });
  const isPending =
    isSessionCreationPending && processingId === info.row.original.id;

  return (
    <div className='flex flex-row'>
      <button
        disabled={isSessionCreationPending || info.row.original.completed}
        className={
          'text-orange font-semibold rounded-lg disabled:opacity-50 disabled:cursor-not-allowed underline disabled:no-underline text-nowrap'
        }
        onClick={() => {
          setProcessingId(String(info.row.original.id));
          createSession(String(info.row.original.id));
        }}
      >
        {info.row.original.completed ? (
          <Tooltip title='Task Completed.'>Create Session</Tooltip>
        ) : isPending ? (
          'Creating Session..'
        ) : (
          'Create Session'
        )}
      </button>
      {!info.row.original.completed && (
        <PopupConfirm
          title='Mark task complete'
          description='Are you sure you want to complete?'
          className='ml-2 text-base disabled:opacity-50'
          onConfirm={() => updateStatus([String(info.getValue())])}
        >
          <Tooltip title='Mark it complete.'>
            <FileDoneOutlined className='text-orange cursor-pointer' />
          </Tooltip>
        </PopupConfirm>
      )}
    </div>
  );
}
