import React from 'react';

const FollowUpsAnalytics = ({
  analytics_data,
  dataLoading
}: {
  analytics_data: any;
  dataLoading: boolean;
}) => {
  const {
    total_sessions_count = 0,
    ai_processed_count = 0,
    completed_count = 0,
    scheduled_orders_count = 0,
    reviewed_count = 0,
    has_session_note_count = 0,
    order_placed_count = 0
  } = analytics_data || {};

  const details = [
    {
      label: 'Sessions Count',
      value: total_sessions_count
    },
    {
      label: 'Form Completed',
      value: completed_count
    },
    {
      label: 'AI Processed',
      value: ai_processed_count
    },
    {
      label: 'Reviewed',
      value: reviewed_count
    },
    {
      label: 'PB Session Notes',
      value: has_session_note_count
    },
    {
      label: 'Orders Scheduled',
      value: scheduled_orders_count
    },
    {
      label: 'DB Orders',
      value: order_placed_count
    }
  ];

  return (
    <div className='flex flex-row gap-5 m-2'>
      {details.map(({ label, value }, index) => (
        <div
          key={index}
          className='flex flex-row justify-between border border-orange bg-light-orange rounded-lg p-1.5'
        >
          <div className='flex flex-col justify-end text-end'>
            <span className='font-semibold text-orange text-sm'>{label}</span>
            <span className='font-medium text-slate-600 text-sm mx-2'>
              {dataLoading ? 'loading...' : value}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FollowUpsAnalytics;
