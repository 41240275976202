import React from 'react';
import paths from 'constants/path';
import { FaSort } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import formatDate from 'utils/helpers/date';
import { ColumnDef } from '@tanstack/react-table';
import { capitalizeFirstLetter } from 'utils/helpers/capitalize';
import { VirtualFollowupsResponse } from 'types/virtualFollowups';
import { getStatusColorClasses } from 'utils/helpers/statusColourClasses';

export const Columns = (
  archiveData: (ids: string[]) => void,
  setSortingDetails: any,
  sortingDetails: any
): ColumnDef<VirtualFollowupsResponse>[] => [
  // {
  //   id: 'select',
  //   header: ({ table }) => (
  //     <IndeterminateCheckbox
  //       {...{
  //         checked: table.getIsAllRowsSelected(),
  //         indeterminate: table.getIsSomeRowsSelected(),
  //         onChange: table.getToggleAllRowsSelectedHandler()
  //       }}
  //     />
  //   ),
  //   cell: ({ row }) => (
  //     <div className='px-1'>
  //       <IndeterminateCheckbox
  //         {...{
  //           checked: row.getIsSelected(),
  //           disabled: !row.getCanSelect(),
  //           indeterminate: row.getIsSomeSelected(),
  //           onChange: row.getToggleSelectedHandler()
  //         }}
  //       />
  //     </div>
  //   )
  // },
  {
    header: 'Name',
    accessorKey: 'name',
    cell: (info) => (
      <a
        href={`https://my.practicebetter.io/#/p/clients/${info.row.original.client_record_id}`}
        rel='noopener noreferrer'
        target='_blank'
        className='flex items-center gap-2'
      >
        <p className='underline text-orange underline-offset-4'>
          {info.getValue<string>()}
        </p>
      </a>
    )
  },
  {
    header: 'Email',
    accessorKey: 'email',
    cell: (info) => (
      <Link
        to={paths.customer(String(info.row.original.client_record_id))}
        className='text-orange underline underline-offset-4'
      >
        {info.getValue<string>()}
      </Link>
    )
  },
  {
    header: () => (
      <div className='flex items-center'>
        <span className='px-1 text-nowrap'>Session Date</span>
        <FaSort
          className='cursor-pointer'
          onClick={() =>
            setSortingDetails({
              sort_by: 'session_date',
              sort_order:
                sortingDetails?.sort_by === 'session_date' &&
                sortingDetails?.sort_order === 'desc'
                  ? 'asc'
                  : 'desc'
            })
          }
        />
      </div>
    ),
    accessorKey: 'session_date',
    cell: (info) => formatDate(info.getValue() as string)
  },
  {
    header: 'Current Medication',
    accessorKey: 'medication',
    cell: (info) =>
      info.getValue() ? (
        <p className='border border-blue rounded-full py-1 px-2 text-xs text-blue bg-light-blue text-center capitalize'>
          {capitalizeFirstLetter(info.getValue() as string)}
        </p>
      ) : (
        '---'
      )
  },
  {
    header: 'Weight(lbs)',
    accessorKey: 'weight'
  },
  {
    header: 'Satisfaction',
    accessorKey: 'satisfaction'
  },
  {
    header: () => (
      <div className='flex items-center'>
        <span className='px-1 text-nowrap'>Form Completed</span>
        <FaSort
          className='cursor-pointer'
          onClick={() =>
            setSortingDetails({
              sort_by: 'completed',
              sort_order:
                sortingDetails?.sort_by === 'completed' &&
                sortingDetails?.sort_order === 'desc'
                  ? 'asc'
                  : 'desc'
            })
          }
        />
      </div>
    ),
    accessorKey: 'completed',
    cell: (info) => (
      <p
        className={`border rounded-full py-1 px-2 text-xs text-center w-[3rem] ${getStatusColorClasses(info.getValue() ? 'Yes' : 'No')}`}
      >
        {info.getValue() ? 'Yes' : 'No'}
      </p>
    )
  },
  {
    header: () => (
      <div className='flex items-center'>
        <span className='px-1 text-nowrap'>Draft Date</span>
        <FaSort
          className='cursor-pointer'
          onClick={() =>
            setSortingDetails({
              sort_by: 'created_at',
              sort_order:
                sortingDetails?.sort_by === 'created_at' &&
                sortingDetails?.sort_order === 'desc'
                  ? 'asc'
                  : 'desc'
            })
          }
        />
      </div>
    ),
    accessorKey: 'created_at',
    cell: (info) => formatDate(info.getValue() as string)
  },
  {
    header: 'Status',
    accessorKey: 'status',
    cell: (info) =>
      info.getValue() && (
        <p
          className={`border rounded-full py-1 px-2 text-xs text-center w-[8rem] ${getStatusColorClasses(info.getValue() as string)}`}
        >
          {info.getValue() as string}
        </p>
      )
  },
  {
    header: 'Consultant',
    accessorKey: 'consultant'
  }
  // {
  //   header: 'Action',
  //   accessorKey: 'id',
  //   cell: (info) => (
  //     <div className='flex items-center gap-3 text-base'>
  //       <Tooltip title='Review details.'>
  //         <Link
  //           to={paths.virtualFollowup(String(info.row.original.id))}
  //           className='text-orange underline underline-offset-4'
  //         >
  //           <EyeOutlined className='mr-1 cursor-pointer' />
  //         </Link>
  //       </Tooltip>
  //       {info.row.original.status !== 'Archive' && (
  //         <PopupConfirm
  //           title='Archive Record'
  //           description='Are you sure you want to archive?'
  //           onConfirm={() => archiveData([String(info.getValue())])}
  //         >
  //           <Tooltip title='Move to archives.'>
  //             <FolderAddOutlined className='text-orange cursor-pointer' />
  //           </Tooltip>
  //         </PopupConfirm>
  //       )}
  //     </div>
  //   )
  // }
];
