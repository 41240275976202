import Tab from 'components/tab/Tab';
import { Outlet, useLocation } from 'react-router-dom';

export default function Settings() {
  const { pathname } = useLocation();

  const items = [
    {
      parent: 'settings',
      key: 'settings/sms-logs',
      label: 'Sms Logs'
    },
    {
      parent: 'settings',
      key: 'settings/email-logs',
      label: 'Email Logs'
    },
    {
      parent: 'settings',
      key: 'settings/api-logs',
      label: 'Api Logs'
    },
    {
      parent: 'settings',
      key: 'settings/packages',
      label: 'Packages'
    },
    {
      parent: 'settings',
      key: 'settings/users',
      label: 'Users'
    },
    {
      parent: 'settings',
      key: 'settings/reports',
      label: 'Reports'
    },
    {
      parent: 'settings',
      key: 'settings/virtual-followups',
      label: 'Virtual Follow-ups'
    }
  ];

  return (
    <>
      <div className='sticky top-[58px] z-50 bg-white'>
        <Tab
          items={items}
          defaultActiveKey='sms-logs'
          activeKey={`${pathname.split('/')[1]}/${pathname.split('/')[2]}`}
        />
      </div>
      <Outlet />
    </>
  );
}
