import React from 'react';
import paths from 'constants/path';
import { Link } from 'react-router-dom';
import formatDate from 'utils/helpers/date';
import { SmsLogsResponse } from 'types/smsLogs';
import { ColumnDef } from '@tanstack/react-table';

export const Columns = (): ColumnDef<SmsLogsResponse>[] => [
  {
    header: 'To',
    accessorKey: 'to'
  },
  {
    header: 'From',
    accessorKey: 'from'
  },
  {
    header: 'Message',
    accessorKey: 'message'
  },
  {
    header: 'Status',
    accessorKey: 'status'
  },
  {
    header: 'Sms sid',
    accessorKey: 'sms_sid'
  },
  {
    header: 'Created At',
    accessorKey: 'created_at',
    cell: (info) => formatDate(info.getValue<string>())
  },
  {
    header: 'Updated At',
    accessorKey: 'updated_at',
    cell: (info) => formatDate(info.getValue<string>())
  },
  {
    header: 'Action',
    accessorKey: '',
    cell: (info) => (
      <div className='flex flex-row gap-2'>
        <Link
          to={paths.customer(info.row.original.client_record_id)}
          className='text-orange underline underline-offset-4'
        >
          Customer
        </Link>
        <Link
          to={info.row.original.log_url}
          className='text-orange underline underline-offset-4'
          target='_blank'
        >
          Twilio
        </Link>
      </div>
    )
  }
];
