import { request } from 'utils/api';
import { UsersFormInputs } from 'types/users';

export const getUsers = async (currentPage: number, rows: number) => {
  const data = await request({
    url: '/users',
    method: 'GET',
    params: { page: currentPage, per_page: rows }
  });
  return data;
};

export const handleUpdateUser = async (
  payload: UsersFormInputs,
  id: string
) => {
  const response = await request({
    url: `/users/${id}`,
    method: 'PUT',
    data: payload
  });
  return response;
};
