import React from 'react';
import dayjs from 'dayjs';
import Select from 'components/select/Select';
import Button from 'components/buttons/Button';
import { Label } from 'components/label/Label';
import { SearchOutlined } from '@ant-design/icons';
import { MdOutlineFilterAltOff } from 'react-icons/md';
import RangePicker from 'components/rangePicker/RangePicker';
import { useUsers } from 'pages/settings/pages/users/api/useApi';
import { FollowUpReportsFilterInput } from 'types/FollowUpReports';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';
import { sessionTypeOptions } from 'constants/sessionTypeOptions';

type FiltersProps = {
  setFollowUpReportsFilters: any;
  setCurrentPage: (page: number) => void;
  followUpReportsFilters: Partial<FollowUpReportsFilterInput>;
};

export default function Filters({
  setFollowUpReportsFilters,
  setCurrentPage,
  followUpReportsFilters
}: FiltersProps) {
  const { control, handleSubmit, reset } = useForm<FollowUpReportsFilterInput>({
    defaultValues: {
      ...followUpReportsFilters
    }
  });
  const { data: usersData } = useUsers(1, 30);

  const onSubmit: SubmitHandler<FollowUpReportsFilterInput> = (data) => {
    data.session_date_gte = data.session_date?.[0];
    data.session_date_lte = data.session_date?.[1];
    const updatedData = {
      ...data,
      session_date: data.session_date || [],
      session_date_gte: data.session_date?.[0] || '',
      session_date_lte: data.session_date?.[1] || ''
    };
    setFollowUpReportsFilters(updatedData);
    setCurrentPage(1);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='filters flex gap-6 items-center my-4'>
        {/* <div>
          <Label className='text-sm !font-normal m-1'>Reviewed At</Label>
          <Controller
            control={control}
            name='reviewed_at'
            render={({ field }) => (
              <RangePicker
                className='!px-2.5 !py-1.5'
                format='MM/DD/YYYY'
                placeholder={['Creation Start Date', 'Creation End Date']}
                {...field}
                onChange={(_, dateStrings: [string, string]) => {
                  console.log(dateStrings);
                  field.onChange(dateStrings);
                }}
                value={
                  field.value
                    ? [
                        field.value[0] ? dayjs(field.value[0]) : null,
                        field.value[1] ? dayjs(field.value[1]) : null
                      ]
                    : null
                }
              />
            )}
          />
        </div> */}
        <div>
          <Label className='text-sm !font-normal m-1'>Session Date</Label>
          <Controller
            control={control}
            name='session_date'
            render={({ field }) => (
              <RangePicker
                className='!px-2.5 !py-1'
                format='MM/DD/YYYY'
                placeholder={['Start Date', 'End Date']}
                {...field}
                onChange={(_, dateStrings: [string, string]) => {
                  console.log(dateStrings);
                  field.onChange(dateStrings);
                }}
                value={
                  field.value
                    ? [
                        field.value[0] ? dayjs(field.value[0]) : null,
                        field.value[1] ? dayjs(field.value[1]) : null
                      ]
                    : null
                }
              />
            )}
          />
        </div>
        <div>
          <Label className='text-sm !font-normal m-1'>Session Type</Label>
          <Controller
            name='session_type'
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                className='w-[180px] h-[1.9rem]]'
                options={sessionTypeOptions}
              />
            )}
          />
        </div>
        <div>
          <Label className='text-sm !font-normal m-1'>Consultant</Label>
          <Controller
            name='consultant_id'
            control={control}
            render={({ field }) => (
              <Select
                options={usersData?.users
                  ?.filter(
                    (data: { pb_consultant_id: string }) =>
                      !!data.pb_consultant_id
                  )
                  .map(
                    (data: {
                      pb_consultant_id: string;
                      first_name: string;
                      last_name: string;
                    }) => ({
                      value: data.pb_consultant_id,
                      label: `${data.first_name} ${data.last_name}`
                    })
                  )}
                className='w-[180px] h-[2rem]'
                {...field}
              />
            )}
          />
        </div>
        <Button type='submit' className='w-max px-3.5 h-[38px] mt-6'>
          <SearchOutlined className='!text-white' />
        </Button>
        <button
          onClick={() =>
            reset({
              session_date: null,
              session_type: '',
              consultant_id: ''
            })
          }
          className='border border-orange rounded py-2 px-3 mt-6'
        >
          <MdOutlineFilterAltOff className='text-orange' />
        </button>
      </div>
    </form>
  );
}
