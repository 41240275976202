import { request } from 'utils/api';

export const getEmailLogs = async (currentPage: number) => {
  const data = await request({
    url: '/email-logs',
    method: 'GET',
    params: { page: currentPage }
  });
  return data;
};
