import paths from 'constants/path';
import { Link } from 'react-router-dom';
import { FaSort } from 'react-icons/fa';
import formatDate from 'utils/helpers/date';
import { ColumnDef } from '@tanstack/react-table';
import { FollowUpReportsResponse } from 'types/FollowUpReports';
import Tooltip from 'components/tooltip/Tooltip';
import { InfoCircleOutlined } from '@ant-design/icons';

export const Columns = (
  sortingDetails: any,
  setSortingDetails: any
): ColumnDef<FollowUpReportsResponse>[] => [
  {
    header: () => (
      <div className='flex items-center'>
        <span className='px-1 text-nowrap'>Session Date</span>
        <FaSort
          className='cursor-pointer'
          onClick={() =>
            setSortingDetails({
              sort_by: 'session_date',
              sort_order:
                sortingDetails?.sort_by === 'session_date' &&
                sortingDetails?.sort_order === 'desc'
                  ? 'asc'
                  : 'desc'
            })
          }
        />
      </div>
    ),
    accessorKey: 'session_date',
    cell: (info) =>
      info.getValue() && info.getValue() !== 'N/A'
        ? formatDate(info.getValue<string>())
        : 'Not Available'
  },
  {
    header: 'Name',
    accessorKey: 'name',
    cell: (info) => (
      <a
        href={`https://my.practicebetter.io/#/p/clients/${info.row.original.client_record_id}`}
        rel='noopener noreferrer'
        target='_blank'
        className='flex items-center gap-2'
      >
        <p className='underline text-orange underline-offset-4'>
          {info.getValue<string>()}
        </p>
      </a>
    )
  },
  {
    header: 'Email',
    accessorKey: 'email',
    cell: (info) => (
      <Link
        to={paths.customer(String(info.row.original.client_record_id))}
        className='text-orange underline underline-offset-4'
      >
        {info.getValue<string>()}
      </Link>
    )
  },
  {
    header: 'Consultant',
    accessorKey: 'consultant'
  },
  {
    header: 'Session Type',
    accessorKey: 'session_type',
    cell: (info) =>
      info.getValue() && (
        <span className='text-nowrap'>
          <Tooltip title={String(info.getValue())}>
            {String(info.getValue()).length < 30
              ? String(info.getValue())
              : `${String(info.getValue()).slice(0, 22)}`}
            <InfoCircleOutlined className='ml-1' />
          </Tooltip>
        </span>
      )
  },
  {
    header: () => (
      <div className='flex items-center'>
        <span className='px-1 text-nowrap'>Form Completed At</span>
        <FaSort
          className='cursor-pointer'
          onClick={() =>
            setSortingDetails({
              sort_by: 'completed_at',
              sort_order:
                sortingDetails?.sort_by === 'completed_at' &&
                sortingDetails?.sort_order === 'desc'
                  ? 'asc'
                  : 'desc'
            })
          }
        />
      </div>
    ),
    accessorKey: 'completed_at',
    cell: (info) => formatDate(info.getValue<string>())
  },
  {
    header: 'Has Completed',
    accessorKey: 'completed',
    cell: (info) => (info.row.original.completed ? 'Yes' : 'No')
  },
  {
    header: 'AI Processed',
    accessorKey: 'ai_processed',
    cell: (info) => (info.row.original.ai_processed ? 'Yes' : 'No')
  },
  {
    header: 'Has Reviewed',
    accessorKey: 'reviewed_status',
    cell: (info) => (info.row.original.reviewed_status ? 'Yes' : 'No')
  },
  {
    header: 'Has Session Notes',
    accessorKey: 'has_session_note',
    cell: (info) => (info.row.original.has_session_note ? 'Yes' : 'No')
  },
  {
    header: 'Has Order Scheduled',
    accessorKey: 'order_schedule',
    cell: (info) => (info.row.original.order_schedule ? 'Yes' : 'No')
  },
  {
    header: 'Has Order Placed',
    accessorKey: 'order_placed',
    cell: (info) => (info.row.original.order_placed ? 'Yes' : 'No')
  }
];
