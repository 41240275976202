import {
  VirtualFollowupsFilter,
  VirtualFollowupsFormInputs
} from 'types/virtualFollowups';
import { request } from 'utils/api';

export const getVirtualFollowups = async (
  currentPage: number,
  params?: VirtualFollowupsFilter,
  sortingDetails?: any
) => {
  const data = await request({
    url: '/virtual-follow-ups',
    method: 'GET',
    params: { page: currentPage, ...params, ...sortingDetails }
  });
  return data;
};

export const getVirtualFollowup = async (id: string) => {
  const data = await request({
    url: `/virtual-follow-ups/${id}`,
    method: 'GET'
  });
  return data;
};

export const syncVirtualFollowups = async (id: string) => {
  const data = await request({
    url: `/virtual-follow-ups/sync-forms/${id}`,
    method: 'GET'
  });
  return data;
};

export const handleArchiveData = async (Ids: string[]) => {
  const response = await request({
    url: 'virtual-follow-ups/status-update',
    method: 'PUT',
    data: { ids: Ids }
  });
  return response;
};

export const handleUpdateVirtualFollowups = async (
  payload: VirtualFollowupsFormInputs,
  id: string
) => {
  const response = await request({
    url: `virtual-follow-ups/${id}`,
    method: 'PUT',
    data: payload
  });
  return response;
};

export const handleUpdateReviewedVFs = async (
  payload: VirtualFollowupsFormInputs,
  id: string
) => {
  const response = await request({
    url: `/virtual-follow-ups/update-session-notes/${id}`,
    method: 'PUT',
    data: payload
  });
  return response;
};

// const getClientInvoices = async (id: string) => {
//   const response = await request({
//     url: `invoices/client/${id}`,
//     method: 'GET'
//   });
//   return response;
// };

// const handleInvoiceAssociation = async (data: {
//   invoiceID: string;
//   vfId: string;
// }) => {
//   const response = await request({
//     url: `/invoices/associate/${data.invoiceID}/virtual_follow_up/${data.vfId}`,
//     method: 'PUT',
//     data: {}
//   });
//   return response;
// };

export const handleSendEmail = async (Id: string) => {
  const response = await request({
    url: `virtual-follow-ups/send-task-email/${Id}`,
    method: 'PUT',
    data: { task_message: '' }
  });
  return response;
};

export const handleRefreshAIPrompts = async (
  columnName: string,
  id: string
) => {
  const response = await request({
    url: `virtual-follow-ups/refresh-ai-prompts/${id}`,
    method: 'PUT',
    data: { column_name: columnName }
  });
  return response;
};

export const handleSessionNotes = async (
  payload: {
    status?: string;
  },
  Id: string
) => {
  const response = await request({
    url: `/virtual-follow-ups/make-session-note/${Id}`,
    method: 'PUT',
    data: payload
  });
  return response;
};
