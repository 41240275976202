import { InvoicesFilterInput, InvoicesSortingDetails } from 'types/invoices';
import {
  getInvoices,
  handleInvoiceAutomation,
  refreshInvoice,
  syncInvoices
} from '.';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

export const useInvoices = (
  currentPage: number,
  invoicesFilters: InvoicesFilterInput,
  sortingDetails: InvoicesSortingDetails
) => {
  return useQuery({
    queryKey: ['invoices', currentPage, invoicesFilters, sortingDetails],
    queryFn: () => getInvoices(currentPage, invoicesFilters, sortingDetails)
  });
};

export const useAutomateInvoice = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: string) => handleInvoiceAutomation(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['automate_invoice'] });
      queryClient.refetchQueries({ queryKey: ['invoices'] });
    }
  });
};

export const useSyncInvoices = () => {
  return useQuery({
    queryKey: ['sync_invoices'],
    queryFn: () => syncInvoices(),
    enabled: false
  });
};

export const useRefreshInvoice = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: string) => refreshInvoice(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['refresh_invoice'] });
      queryClient.refetchQueries({ queryKey: ['invoices'] });
    }
  });
};
