import { ICReportsFilterInput, ICReportsSortingDetails } from 'types/ICReports';
import { useQuery } from '@tanstack/react-query';
import { getICReports } from '.';

export const useICReports = (
  currentPage: number,
  icReportsFilters: ICReportsFilterInput,
  sortingDetails: ICReportsSortingDetails
) => {
  return useQuery({
    queryKey: ['ic_reports', currentPage, icReportsFilters, sortingDetails],
    queryFn: () => getICReports(currentPage, icReportsFilters, sortingDetails)
  });
};
