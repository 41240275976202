import ApiBodyView from './ApiBodyView';
import { FaSort } from 'react-icons/fa';
import formatDate from 'utils/helpers/date';
import Drawer from 'components/drawer/Drawer';
import { ApiLogsResponse } from 'types/apiLogs';
import { ColumnDef } from '@tanstack/react-table';
import { ArrowRightOutlined, EyeOutlined } from '@ant-design/icons';

export const Columns = (
  sortingDetails: any,
  setSortingDetails: any
): ColumnDef<ApiLogsResponse>[] => [
  {
    header: () => (
      <div className='flex items-center'>
        <span className='px-1 text-nowrap'>Created At</span>
        <FaSort
          className='cursor-pointer'
          onClick={() =>
            setSortingDetails({
              sort_by: 'created_at',
              sort_order:
                sortingDetails?.sort_by === 'created_at' &&
                sortingDetails?.sort_order === 'desc'
                  ? 'asc'
                  : 'desc'
            })
          }
        />
      </div>
    ),
    accessorKey: 'created_at',
    cell: (info) => formatDate(info.getValue<string>())
  },
  {
    header: 'Method',
    accessorKey: 'method'
  },
  {
    header: 'Status',
    accessorKey: 'status'
  },
  {
    header: 'Path',
    accessorKey: 'path'
  },
  {
    header: 'Action',
    accessorKey: '',
    cell: (info) => (
      <Drawer
        styles={{
          header: { display: 'flex', flexDirection: 'row-reverse' }
        }}
        width='50%'
        button={<EyeOutlined className='mr-1' />}
        title='Api Details'
        closeIcon={<ArrowRightOutlined />}
      >
        <ApiBodyView info={info.row.original} setIsClose={''} />
      </Drawer>
    )
  }
];
