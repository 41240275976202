import {
  FollowUpReportsFilterInput,
  FollowUpReportsSortingDetails
} from 'types/FollowUpReports';
import { useQuery } from '@tanstack/react-query';
import { getFollowUpReports } from '.';

export const useFollowUpsReports = (
  currentPage: number,
  followUpReportsFilters: FollowUpReportsFilterInput,
  sortingDetails: FollowUpReportsSortingDetails
) => {
  return useQuery({
    queryKey: [
      'followUps_reports',
      currentPage,
      followUpReportsFilters,
      sortingDetails
    ],
    queryFn: () =>
      getFollowUpReports(currentPage, followUpReportsFilters, sortingDetails)
  });
};
