import { ApiFilterInput, ApiSortingDetails } from 'types/apiLogs';
import { request } from 'utils/api';

export const getApiLogs = async (
  currentPage: number,
  apiFilters: ApiFilterInput,
  sortingDetails: ApiSortingDetails
) => {
  const data = await request({
    url: '/api-logs',
    method: 'GET',
    params: { page: currentPage, ...apiFilters, ...sortingDetails }
  });
  return data;
};
