import React, { useMemo, useState } from 'react';
import dayjs from 'dayjs';
import paths from 'constants/path';
import { getCookie } from 'utils/cookies';
import Filters from './components/Filters';
import { usePbSessions } from './api/useApi';
import { useParams } from 'react-router-dom';
import Drawer from 'components/drawer/Drawer';
import { Columns } from './components/Columns';
import DataTable from 'components/dataTable/DataTable';
import { RowSelectionState } from '@tanstack/react-table';
import Pagination from 'components/pagination/Pagination';
import HeaderActionButtons from './components/HeaderActionButtons';
import VirtualFollowupsForm from 'pages/settings/pages/virtualFollowups/components/virtualFollowupForm/VirtualFollowupsForm';
import InitialConsultationsForm from 'pages/initialConsultations/components/initialConsultationForm/InitialConsultationsForm';
import {
  useArchiveData,
  useSyncVirtualFollowups
} from 'pages/settings/pages/virtualFollowups/api/useApi';

export default function Followups({
  isSingle,
  customersPageView
}: {
  isSingle?: boolean;
  customersPageView?: boolean;
  customerId?: string;
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const pbConsultantId = getCookie('pb_consultant_id');
  const role = getCookie('role');
  const { id, form_id, form_type } = useParams();
  const default_pb_consultant_id =
    role === 'Super Admin' || !pbConsultantId || pbConsultantId === 'null'
      ? ''
      : String(pbConsultantId);

  const [followupsFilters, setFollowupsFilters] = useState({
    email: '',
    last_name: '',
    first_name: '',
    has_virtual_form: '',
    consultant_id: default_pb_consultant_id,
    completed: '',
    status: '',
    session_type: '',
    session_date: [dayjs().format('MM/DD/YYYY'), dayjs().format('MM/DD/YYYY')],
    session_date_gte: dayjs().format('MM/DD/YYYY'),
    session_date_lte: dayjs().format('MM/DD/YYYY')
  });
  const [sortingDetails, setSortingDetails] = useState({
    sort_by: 'date_created',
    sort_order: 'desc'
  });

  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const selectedRows = useMemo(() => Object.keys(rowSelection), [rowSelection]);
  const handleSuccess = () => {
    setRowSelection({});
  };

  const { data: PbSessionsData, isPending: sessionsLoading } = usePbSessions(
    currentPage,
    followupsFilters,
    sortingDetails,
    customersPageView ? id : undefined
  );
  const { isLoading: isSyncPending, refetch: syncVirtualFollowupsForms } =
    useSyncVirtualFollowups();
  const { mutateAsync: archiveData, isPending: isArchivePending } =
    useArchiveData({ queryKeys: ['pb_sessions'], onSuccess: handleSuccess });

  const pbSessionsMemorizedData = useMemo(() => {
    return PbSessionsData?.form_requests ?? [];
  }, [PbSessionsData?.form_requests]);

  return (
    <div className={`${!customersPageView && 'p-5 bg-[#FAFBFC]'}`}>
      <div
        className={`border border-light-grey rounded-xl p-2.5 ${!customersPageView && 'bg-white'}`}
      >
        <div className='flex justify-between'>
          <h1 className='text-xl font-semibold'>Follow-ups</h1>
          <HeaderActionButtons
            archiveData={archiveData}
            selectedRows={selectedRows}
            isSyncPending={isSyncPending}
            isArchivePending={isArchivePending}
            syncVirtualFollowupsForms={syncVirtualFollowupsForms}
          />
        </div>
        <Filters
          role={role}
          setCurrentPage={setCurrentPage}
          followupsFilters={followupsFilters}
          customersPageView={customersPageView}
          setFollowupsFilters={setFollowupsFilters}
          default_pb_consultant_id={default_pb_consultant_id}
        />

        <div
          className={`overflow-auto ${customersPageView ? 'h-[calc(100vh_-_400px)]' : 'h-[calc(100vh_-_300px)]'}`}
        >
          <DataTable
            rowSelection={rowSelection}
            isLoading={sessionsLoading}
            data={pbSessionsMemorizedData}
            setRowSelection={setRowSelection}
            columns={Columns(
              sortingDetails,
              setSortingDetails,
              archiveData,
              customersPageView,
              id
            )}
          />
        </div>
      </div>
      <Pagination
        isLoading={sessionsLoading}
        totalCount={PbSessionsData?.pagination.total_count}
        totalPage={PbSessionsData?.pagination.total_pages}
        perPage={PbSessionsData?.pagination.per_page}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
      <Drawer
        styles={{ header: { display: 'flex', flexDirection: 'row-reverse' } }}
        width='90%'
        open={Boolean(isSingle)}
        button={null}
        closeIcon={null}
      >
        {form_type === 'ic' ? (
          <InitialConsultationsForm
            isSingle={isSingle}
            formId={customersPageView ? String(form_id) : String(id)}
            prevUrl={customersPageView ? paths.customer(id) : paths.followups}
          />
        ) : (
          <VirtualFollowupsForm
            isSingle={Boolean(isSingle)}
            formId={customersPageView ? String(form_id) : String(id)}
            prevUrl={customersPageView ? paths.customer(id) : paths.followups}
          />
        )}
      </Drawer>
    </div>
  );
}
