import { useMutation, useQueryClient } from '@tanstack/react-query';
import Button from 'components/buttons/Button';
import Input from 'components/input/Input';
import { Label } from 'components/label/Label';
import Select from 'components/select/Select';
import { Controller, useForm } from 'react-hook-form';
import { request } from 'utils/api';

interface OrderFormInputs {
  approved?: boolean | null;
  prescription_notes?: string;
}

const handleUpdateFormRequest = async (
  payload: OrderFormInputs,
  id: string
) => {
  const response = await request({
    url: `/form-requests/${id}`,
    method: 'PUT',
    data: payload
  });
  return response;
};

export default function FormRequestForm({ info, setIsClose }: any) {
  const queryClient = useQueryClient();
  const {
    control,
    handleSubmit,
    formState: { errors, dirtyFields }
  } = useForm<OrderFormInputs>({
    values: info
      ? {
          prescription_notes: info.prescription_notes,
          approved: info.approved
        }
      : {
          prescription_notes: '',
          approved: ''
        }
  });

  const { mutateAsync: updateOrder, isPending: isUpdatePending } = useMutation({
    mutationFn: (data: OrderFormInputs) =>
      handleUpdateFormRequest(data, info.id),
    onSuccess(data) {
      if (data !== undefined) {
        queryClient.invalidateQueries({ queryKey: ['formRequests'] });
        setIsClose();
      }
    }
  });

  const onSubmit = (data: any) => {
    const dirtyData: OrderFormInputs = {};
    Object.keys(data).forEach((key) => {
      const objKey = key as keyof OrderFormInputs;
      if (dirtyFields[objKey]) {
        dirtyData[objKey] = data[objKey];
      }
    });
    if (info) {
      updateOrder(dirtyData);
    }
  };

  const isPending = isUpdatePending;

  return (
    <>
      <p className='text-base font-semibold'>Form Request Detail</p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='grid grid-cols-2 mt-3.5 gap-6'>
          <div>
            <Label className='!font-normal'>Approved*</Label>
            <Controller
              control={control}
              rules={{ required: 'Approved is Required' }}
              name='approved'
              render={({ field }) => (
                <Select
                  className='w-full mt-2 h-[38px]'
                  {...field}
                  placeholder='Select'
                  options={[
                    { value: 1, label: 'True' },
                    { value: 0, label: 'False' }
                  ]}
                />
              )}
            />
            <p className='text-red text-sm'>{errors?.approved?.message}</p>
          </div>
          <div>
            <Label className='!font-normal'>Prescription Notes*</Label>
            <Controller
              name='prescription_notes'
              rules={{ required: 'Prescription Notes is Required' }}
              control={control}
              render={({ field }) => (
                <Input type='text' className='mt-2' {...field} />
              )}
            />
            <p className='text-red text-sm'>
              {errors?.prescription_notes?.message}
            </p>
          </div>
        </div>
        <div className='flex gap-5 bg-white justify-end absolute bottom-0 right-0 px-4 py-3 border-t w-full'>
          <Button className='!w-fit py-3 px-8' isLoading={isPending}>
            Submit
          </Button>
        </div>
      </form>
    </>
  );
}
