import React from 'react';
import { useMutation } from '@tanstack/react-query';
import { Label } from 'components/label/Label';
import { SubmitHandler, useForm } from 'react-hook-form';
import { request } from 'utils/api';
import Button from 'components/buttons/Button';
import Input from 'components/input/Input';
import Checkbox from 'components/checkbox/Checkbox';
import { useNavigate } from 'react-router-dom';
import { setCookie } from 'utils/cookies';
import ErrorMessage from 'components/errorMessage/ErrorMessage';

interface LoginFormInputs {
  email: string;
  password: string;
  remember: boolean;
}

const handleLogin = async (payload: LoginFormInputs) => {
  const response = await request({
    url: '/login',
    method: 'POST',
    data: payload
  });
  return response;
};

export default function LoginForm() {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<LoginFormInputs>();

  const onSubmit: SubmitHandler<LoginFormInputs> = (data) => {
    updateUserDetails(data);
  };

  const { mutateAsync: updateUserDetails, isPending } = useMutation({
    mutationFn: (data: LoginFormInputs) => handleLogin(data),
    onSuccess(data) {
      if (data !== undefined) {
        setCookie('token', data?.token);
        setCookie(
          'fullName',
          `${data?.user?.first_name} ${data?.user?.last_name}`
        );
        setCookie('role', data?.user?.role);
        setCookie('pb_consultant_id', data?.user?.pb_consultant_id);
        navigate('/');
      }
    }
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='mb-5'>
        <Label>Email</Label>
        <Input
          type='email'
          placeholder='Enter your Email'
          className='py-3 px-5 mt-2'
          {...register('email', { required: 'Email is required' })}
        />
        <ErrorMessage error={errors.email} />
      </div>
      <div className='mb-5'>
        <Label>Password</Label>
        <Input
          type='password'
          placeholder='Enter your Password'
          className='py-3 px-5 mt-2'
          {...register('password', { required: 'Password is required' })}
        />
        <ErrorMessage error={errors.password} />
      </div>
      <div className='flex items-center mb-6 gap-2'>
        <Checkbox id='remember' {...register('remember')} />
        <Label htmlFor='remember' className='text-grey font-normal'>
          Remember Me
        </Label>
      </div>
      <Button isLoading={isPending}>Login</Button>
    </form>
  );
}
