import React from 'react';
import UsersForm from './form/UsersForm';
import { UsersResponse } from 'types/users';
import { joinName } from 'utils/helpers/name';
import Drawer from 'components/drawer/Drawer';
import { ColumnDef } from '@tanstack/react-table';
import { ArrowRightOutlined, EditOutlined } from '@ant-design/icons';

export const columns = (): ColumnDef<UsersResponse>[] => [
  {
    header: 'Name',
    accessorKey: 'first_name',
    cell: (info) => (
      <a
        href={`https://my.practicebetter.io/#/p/clients/${info.row.original.id}`}
        rel='noopener noreferrer'
        target='_blank'
        className='flex items-center gap-2'
      >
        <p className='underline text-orange underline-offset-4'>
          {joinName(info.getValue<string>(), info.row.original.last_name)}
        </p>
      </a>
    )
  },
  {
    header: 'Email',
    accessorKey: 'email'
  },
  {
    header: 'Consultant Type',
    accessorKey: 'pb_consultant_id',
    cell: (info) =>
      info.getValue<string>() ? (
        <a
          href='https://my.practicebetter.io/#/p/home'
          rel='noopener noreferrer'
          target='_blank'
          className='flex items-center gap-2 text-orange'
        >
          PB Consultant
        </a>
      ) : (
        'Zappy Consultant'
      )
  },
  {
    header: 'Role',
    accessorKey: 'role'
  },

  {
    header: 'Action',
    accessorKey: 'id',
    cell: (info) => (
      <div className='flex flex-row gap-2'>
        <Drawer
          styles={{
            header: { display: 'flex', flexDirection: 'row-reverse' }
          }}
          width='50%'
          button={
            <>
              <EditOutlined className='mr-1' />
              Edit
            </>
          }
          title='Edit Package'
          closeIcon={<ArrowRightOutlined />}
        >
          <UsersForm info={info.row.original} />
        </Drawer>
      </div>
    )
  }
];
