import React from 'react';
import Button from 'components/buttons/Button';
import Textarea from 'components/textarea/Textarea';
import { useSendTaskMessageEmail } from '../api/useApi';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

interface SendEmailFormProps {
  processingModalId: string;
  setProcessingModalId: any;
}

interface SendEmailFormInputs {
  task_message: string;
}

export default function SendEmailForm({
  processingModalId,
  setProcessingModalId
}: SendEmailFormProps) {
  const { control, handleSubmit, reset, watch } = useForm<SendEmailFormInputs>({
    defaultValues: {}
  });

  const handleSubmitSuccess = () => {
    setProcessingModalId('');
    reset();
  };
  const { mutateAsync: sendTaskMessageEmail, isPending: isSending } =
    useSendTaskMessageEmail({ onSuccess: handleSubmitSuccess });

  const textValue = watch('task_message');
  const onSubmit: SubmitHandler<SendEmailFormInputs> = (data) => {
    sendTaskMessageEmail({
      id: processingModalId,
      formData: { ...data }
    });
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h2 className='text-lg font-semibold text-orange mb-2'>Add Message</h2>
      <Controller
        name='task_message'
        control={control}
        render={({ field }) => <Textarea type='text' {...field} />}
      />
      <Button
        className={`!w-fit py-3 px-8 my-3 mx-[9rem] ${!textValue && 'opacity-50 cursor-not-allowed'}`}
        isLoading={isSending}
      >
        Send Email
      </Button>
    </form>
  );
}
