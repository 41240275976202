import React from 'react';
import { Spin } from 'antd';
import { Link } from 'react-router-dom';

interface FormFooterButtonsProps {
  prevUrl: string;
  isPending: boolean;
  followUpClicked: boolean;
  showSubmitButton: boolean;
  setFollowUpClicked: (value: boolean) => void;
}

const FormFooterButtons: React.FC<FormFooterButtonsProps> = ({
  prevUrl,
  isPending,
  followUpClicked,
  showSubmitButton,
  setFollowUpClicked
}) => {
  return (
    <div className='flex gap-5 bg-white justify-end absolute bottom-0 right-0 px-4 py-3 border-t w-full'>
      <Link to={prevUrl}>
        <button
          type='button'
          className='!w-fit py-3 px-8 bg-grey text-white py-2 rounded-lg font-semibold'
        >
          Cancel
        </button>
      </Link>
      <button
        disabled={isPending}
        type='submit'
        className='!w-fit py-3 px-8 bg-yellow-500 text-white py-2 rounded-lg font-semibold disabled:cursor-not-allowed'
        onClick={() => setFollowUpClicked(true)}
      >
        {followUpClicked && isPending ? <Spin /> : 'Follow-up'}
      </button>
      {showSubmitButton && (
        <button
          disabled={isPending}
          type='submit'
          className='!w-fit py-3 px-8 bg-orange text-white py-2 rounded-lg font-semibold disabled:cursor-not-allowed w-36'
          onClick={() => setFollowUpClicked(false)}
        >
          {!followUpClicked && isPending ? <Spin /> : 'Submit'}
        </button>
      )}
    </div>
  );
};

export default FormFooterButtons;
