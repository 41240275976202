import { ApiFilterInput, ApiSortingDetails } from 'types/apiLogs';
import { useQuery } from '@tanstack/react-query';
import { getApiLogs } from '.';

export const useApiLogs = (
  currentPage: number,
  apiFilters: ApiFilterInput,
  sortingDetails: ApiSortingDetails
) => {
  return useQuery({
    queryKey: ['api-logs', currentPage, apiFilters, sortingDetails],
    queryFn: () => getApiLogs(currentPage, apiFilters, sortingDetails)
  });
};
