import { useQuery } from '@tanstack/react-query';
import { getPbSessions } from '.';
import { FollowupsFilter } from 'types/follow-ups';

// pb sessions
export const usePbSessions = (
  currentPage: number,
  followupsFilters: FollowupsFilter,
  sortingDetails?: any,
  id?: string
) => {
  return useQuery({
    queryKey: [
      'pb_sessions',
      currentPage,
      followupsFilters,
      sortingDetails,
      id
    ],
    queryFn: () =>
      getPbSessions(currentPage, followupsFilters, sortingDetails, id)
  });
};
