export const semaglutideDoseOptions = [
  { value: '0.25', label: '0.25 mg' },
  { value: '0.5', label: '0.5 mg' },
  { value: '1', label: '1 mg' },
  { value: '1.7', label: '1.7 mg' },
  { value: '2.4', label: '2.4 mg' }
];

export const tirzepatideDoseOptions = [
  { value: '2.5', label: '2.5 mg' },
  { value: '5', label: '5 mg' },
  { value: '7.5', label: '7.5 mg' },
  { value: '10', label: '10 mg' },
  { value: '12.5', label: '12.5 mg' },
  { value: '15', label: '15 mg' }
];
