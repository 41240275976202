import React from 'react';
import { FaSort } from 'react-icons/fa';
import ActionColumn from './ActionColumn';
import { TasksResponse } from 'types/tasks';
import formatDate from 'utils/helpers/date';
import { joinName } from 'utils/helpers/name';
import Tooltip from 'components/tooltip/Tooltip';
import { ColumnDef } from '@tanstack/react-table';
import IndeterminateCheckbox from 'components/indeterminateCheckbox/IndeterminateCheckbox';
import { getStatusColorClasses } from 'utils/helpers/statusColourClasses';

export const Columns = (
  sortingDetails: any,
  setSortingDetails: any,
  updateStatus: (ids: string[]) => void,
  handleSuccess: () => void
): ColumnDef<TasksResponse>[] => [
  {
    id: 'select',
    header: ({ table }) => (
      <IndeterminateCheckbox
        {...{
          checked: table.getIsAllRowsSelected(),
          indeterminate: table.getIsSomeRowsSelected(),
          onChange: table.getToggleAllRowsSelectedHandler()
        }}
      />
    ),
    cell: ({ row }) => (
      <div className='px-1'>
        <IndeterminateCheckbox
          {...{
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler()
          }}
        />
      </div>
    )
  },
  {
    header: () => (
      <div className='flex items-center'>
        <span className='px-1 text-nowrap'>Created At</span>
        <FaSort
          className='cursor-pointer'
          onClick={() =>
            setSortingDetails({
              sort_by: 'created_at',
              sort_order:
                sortingDetails?.sort_by === 'created_at' &&
                sortingDetails?.sort_order === 'desc'
                  ? 'asc'
                  : 'desc'
            })
          }
        />
      </div>
    ),
    accessorKey: 'date_created',
    cell: (info) => formatDate(info.getValue() as string)
  },
  {
    header: 'Name',
    accessorKey: 'first_name',
    cell: (info) => (
      <a
        href={`https://my.practicebetter.io/#/p/clients/${info.row.original.client_record_id}`}
        rel='noopener noreferrer'
        target='_blank'
        className='flex items-center gap-2'
      >
        <p className='underline text-orange underline-offset-4'>
          {joinName(info.getValue<string>(), info.row.original.last_name)}
        </p>
      </a>
    )
  },
  {
    header: 'Email',
    accessorKey: 'email'
  },
  {
    header: 'Consultant',
    accessorKey: 'consultant'
  },
  {
    header: 'Title',
    accessorKey: 'title',
    cell: (info) => (
      <span>
        <Tooltip title={String(info.getValue())}>
          {String(info.getValue()).length < 30
            ? String(info.getValue())
            : `${String(info.getValue()).slice(0, 30)}...`}
        </Tooltip>
      </span>
    )
  },
  {
    header: 'Completed',
    accessorKey: 'completed',
    cell: (info) => (
      <p
        className={`border rounded-full py-1 px-2 text-xs text-center w-[3rem] ${getStatusColorClasses(info.getValue() ? 'Yes' : 'No')}`}
      >
        {info.getValue() ? 'Yes' : 'No'}
      </p>
    )
  },
  {
    header: () => (
      <div className='flex items-center'>
        <span className='px-1 text-nowrap'>Due Date</span>
        <FaSort
          className='cursor-pointer'
          onClick={() =>
            setSortingDetails({
              sort_by: 'due_date',
              sort_order:
                sortingDetails?.sort_by === 'due_date' &&
                sortingDetails?.sort_order === 'desc'
                  ? 'asc'
                  : 'desc'
            })
          }
        />
      </div>
    ),
    accessorKey: 'due_date',
    cell: (info) => formatDate(info.getValue() as string)
  },
  {
    header: () => (
      <div className='flex items-center'>
        <span className='px-1 text-nowrap'>Modified At</span>
        <FaSort
          className='cursor-pointer'
          onClick={() =>
            setSortingDetails({
              sort_by: 'date_modified',
              sort_order:
                sortingDetails?.sort_by === 'date_modified' &&
                sortingDetails?.sort_order === 'desc'
                  ? 'asc'
                  : 'desc'
            })
          }
        />
      </div>
    ),
    accessorKey: 'date_modified',
    cell: (info) => formatDate(info.getValue() as string)
  },
  {
    header: () => (
      <div className='flex items-center'>
        <span className='px-1 text-nowrap'>Message</span>
        <FaSort
          className='cursor-pointer'
          onClick={() =>
            setSortingDetails({
              sort_by: 'message',
              sort_order:
                sortingDetails?.sort_by === 'message' &&
                sortingDetails?.sort_order === 'desc'
                  ? 'asc'
                  : 'desc'
            })
          }
        />
      </div>
    ),
    accessorKey: 'message'
  },
  // {
  //   header: 'Archived',
  //   accessorKey: 'archived',
  //   cell: (info) => <span>{info.getValue() ? 'Yes' : 'No'}</span>
  // },
  {
    header: 'Action',
    accessorKey: 'id',
    cell: (info) => (
      <ActionColumn
        info={info}
        updateStatus={updateStatus}
        handleSuccess={handleSuccess}
      />
    )
  }
];
