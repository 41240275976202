import { request } from 'utils/api';
import { TasksFilterInput, TasksSortingDetails } from 'types/tasks';

export const getTasks = async (
  currentPage: number,
  params?: TasksFilterInput,
  download?: boolean,
  sortingDetails?: TasksSortingDetails
) => {
  const data = await request({
    url: '/tasks',
    method: 'GET',
    params: {
      page: currentPage,
      download: download,
      ...params,
      ...sortingDetails
    }
  });
  return data;
};

export const handleSessionCreation = async (id: string) => {
  const data = await request({
    url: `/tasks/virtual-session/${id}`,
    method: 'PUT',
    data: { task_ids: [id] }
  });
  return data;
};

export const handleUpdateStatus = async (Ids: string[]) => {
  const response = await request({
    url: 'tasks/status-update',
    method: 'PUT',
    data: { ids: Ids }
  });
  return response;
};

export const handleBulkSessionCreation = async (selectedTaskIds: string[]) => {
  const data = await request({
    url: `/tasks/virtual-session/${selectedTaskIds[0]}`,
    method: 'PUT',
    data: { task_ids: selectedTaskIds }
  });
  return data;
};
