import React from 'react';
import Input from 'components/input/Input';
import Button from 'components/buttons/Button';
import { Label } from 'components/label/Label';
import { SearchOutlined } from '@ant-design/icons';
import { MdOutlineFilterAltOff } from 'react-icons/md';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';
import { InvoicesFilterInput } from 'types/invoices';
import Select from 'components/select/Select';
import RangePicker from 'components/rangePicker/RangePicker';
import dayjs from 'dayjs';

type FiltersProps = {
  setInvoicesFilters: any;
  setCurrentPage: (page: number) => void;
  invoicesFilters: Partial<InvoicesFilterInput>;
};

export default function Filters({
  setInvoicesFilters,
  setCurrentPage,
  invoicesFilters
}: FiltersProps) {
  const { control, handleSubmit, reset } = useForm<InvoicesFilterInput>({
    defaultValues: {
      ...invoicesFilters
    }
  });

  const onSubmit: SubmitHandler<InvoicesFilterInput> = (data) => {
    data.date_created_gte = data.date_created?.[0];
    data.date_created_lte = data.date_created?.[1];
    delete data.date_created;
    const updatedData = {
      ...data,
      date_created: data.date_created || []
    };
    setInvoicesFilters(updatedData);
    setCurrentPage(1);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='filters flex gap-6 items-center my-4'>
        <div className='w-40'>
          <Label htmlFor='name' className='text-sm !font-normal m-1'>
            First Name
          </Label>
          <Controller
            name='first_name'
            control={control}
            render={({ field }) => (
              <Input type='text' id='name' className='h-[2rem]' {...field} />
            )}
          />
        </div>
        <div className='w-40'>
          <Label htmlFor='name' className='text-sm !font-normal m-1'>
            Last Name
          </Label>
          <Controller
            name='last_name'
            control={control}
            render={({ field }) => (
              <Input type='text' id='name' className='h-[2rem]' {...field} />
            )}
          />
        </div>
        <div className='w-44'>
          <Label htmlFor='email' className='text-sm !font-normal m-1'>
            Email
          </Label>
          <Controller
            name='email'
            control={control}
            render={({ field }) => (
              <Input type='email' id='email' className='h-[2rem]' {...field} />
            )}
          />
        </div>
        <div>
          <Label htmlFor='status' className='text-sm !font-normal m-1'>
            Status
          </Label>
          <Controller
            name='status'
            control={control}
            render={({ field }) => (
              <Select
                id='archived'
                options={[
                  { value: 'paid', label: 'Paid' },
                  { value: 'unpaid', label: 'Unpaid' }
                ]}
                className='w-[150px]'
                {...field}
              />
            )}
          />
        </div>
        <div>
          <Label className='text-sm !font-normal m-1'>Creation Date</Label>
          <Controller
            control={control}
            name='date_created'
            render={({ field }) => (
              <RangePicker
                className='!px-2.5 !py-1.5'
                format='MM/DD/YYYY'
                placeholder={['Creation Start Date', 'Creation End Date']}
                {...field}
                onChange={(_, dateStrings: [string, string]) => {
                  console.log(dateStrings);
                  field.onChange(dateStrings);
                }}
                value={
                  field.value
                    ? [
                        field.value[0] ? dayjs(field.value[0]) : null,
                        field.value[1] ? dayjs(field.value[1]) : null
                      ]
                    : null
                }
              />
            )}
          />
        </div>
        <Button type='submit' className='w-max px-3.5 h-[38px] mt-5'>
          <SearchOutlined className='!text-white' />
        </Button>
        <button
          onClick={() =>
            reset({
              first_name: '',
              last_name: '',
              email: '',
              status: '',
              date_created: null
            })
          }
          className='border border-orange rounded py-2.5 px-3 mt-5'
        >
          <MdOutlineFilterAltOff className='text-orange' />
        </button>
      </div>
    </form>
  );
}
