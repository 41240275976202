import {
  FollowUpReportsFilterInput,
  FollowUpReportsSortingDetails
} from 'types/FollowUpReports';
import { request } from 'utils/api';

export const getFollowUpReports = async (
  currentPage: number,
  followUpReportFilters: FollowUpReportsFilterInput,
  sortingDetails: FollowUpReportsSortingDetails,
  downloadData?: boolean
) => {
  const data = await request({
    url: '/reports/sessions-analytics',
    method: 'GET',
    params: {
      page: currentPage,
      ...followUpReportFilters,
      ...sortingDetails,
      download: downloadData ?? false
    }
  });
  return data;
};
