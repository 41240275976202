import { useQuery } from '@tanstack/react-query';
import React, { useMemo, useState } from 'react';
import { request } from 'utils/api';
import { ColumnDef } from '@tanstack/react-table';
import { Link } from 'react-router-dom';
import paths from 'constants/path';
import DataTable from 'components/dataTable/DataTable';
import Pagination from 'components/pagination/Pagination';
import Tooltip from 'components/tooltip/Tooltip';
import { joinName } from 'utils/helpers/name';
import { Order } from 'types/order';
import Button from 'components/buttons/Button';
import {
  ArrowRightOutlined,
  EditOutlined,
  EyeOutlined,
  SearchOutlined
} from '@ant-design/icons';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import Input from 'components/input/Input';
import { MdOutlineFilterAltOff } from 'react-icons/md';
import formatDate from 'utils/helpers/date';
import RangePicker from 'components/rangePicker/RangePicker';
import dayjs from 'dayjs';
import Drawer from 'components/drawer/Drawer';
import OrderForm from 'pages/customer/components/OrderForm';
import Select from 'components/select/Select';
import OrdersKPIs from './components/OrdersKPIs';
import { FaSort } from 'react-icons/fa';
import { getStatusColorClasses } from 'utils/helpers/statusColourClasses';
import { Label } from 'components/label/Label';
import OrderTimeline from './components/OrderTimeline';

interface OrderFilterInput {
  tracking_number: string;
  email: string;
  order_date?: string[] | null;
  start_order_date?: string;
  end_order_date?: string;
  status: string;
  order_delivered: string;
  created_at?: string[] | null;
  start_created_at?: string;
  end_created_at?: string;
  order_source: string;
}

const getOrders = async (
  currentPage: number,
  params?: OrderFilterInput,
  sortingDetails?: any
) => {
  const data = await request({
    url: '/orders',
    method: 'GET',
    params: { page: currentPage, ...params, ...sortingDetails }
  });
  return data;
};

const getOrderStatuses = async () => {
  const data = await request({
    url: '/orders/ups-dropdown',
    method: 'GET'
  });
  return data;
};

export const useOrderStatuses = () => {
  return useQuery({
    queryKey: ['order_statuses'],
    queryFn: () => getOrderStatuses()
  });
};

export default function OrdersPage() {
  const [currentPage, setCurrentPage] = useState(1);

  const [orderFilters, setOrderFilters] = useState<OrderFilterInput>({
    tracking_number: '',
    email: '',
    status: '',
    order_delivered: '',
    order_source: '',
    created_at: [],
    order_date: [
      dayjs().subtract(2, 'month').format('MM/DD/YYYY'),
      dayjs().format('MM/DD/YYYY')
    ],
    start_order_date: dayjs().subtract(2, 'month').format('MM/DD/YYYY'),
    end_order_date: dayjs().format('MM/DD/YYYY')
  });
  const [sortingDetails, setSortingDetails] = useState({
    sort_by: 'created_at',
    sort_order: 'desc'
  });

  const { data: orderData, isLoading } = useQuery({
    queryKey: ['orders', currentPage, orderFilters, sortingDetails],
    queryFn: () => getOrders(currentPage, orderFilters, sortingDetails)
  });

  const { data: orderStatuses } = useOrderStatuses();

  const customerMemorizedData = useMemo(() => {
    return orderData?.orders ?? [];
  }, [orderData?.orders]);

  const { control, handleSubmit, reset } = useForm<OrderFilterInput>({
    defaultValues: {
      ...orderFilters,
      start_order_date: dayjs().subtract(2, 'month').format('MM/DD/YYYY'),
      end_order_date: dayjs().format('MM/DD/YYYY')
    }
  });

  const onSubmit: SubmitHandler<OrderFilterInput> = (data) => {
    data.start_order_date = data.order_date?.[0];
    data.end_order_date = data.order_date?.[1];
    data.start_created_at = data.created_at?.[0];
    data.end_created_at = data.created_at?.[1];
    const updatedData = {
      ...data,
      order_date: data.order_date || [],
      created_at: data.created_at || [],
      start_order_date: data.order_date?.[0] || '',
      end_order_date: data.order_date?.[1] || ''
    };

    setOrderFilters(updatedData);
    setCurrentPage(1);
  };

  return (
    <div className='p-5 bg-[#FAFBFC]'>
      <div className='border border-light-grey bg-white rounded-xl p-2.5'>
        <div className='flex flex-row justify-between'>
          <h1 className='text-xl font-semibold'>Orders</h1>
          <OrdersKPIs
            ordersAnalytics={orderData?.orders_analytics}
            dataLoading={isLoading}
          />
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='filters flex gap-6 items-center my-4'>
            <div>
              <Label className='text-sm !font-normal m-1'>Email</Label>
              <Controller
                name='email'
                control={control}
                render={({ field }) => (
                  <Input className='h-[2rem]' type='email' {...field} />
                )}
              />
            </div>
            <div>
              <Label className='text-sm !font-normal m-1'>
                Tracking Number
              </Label>
              <Controller
                name='tracking_number'
                control={control}
                render={({ field }) => (
                  <Input type='text' className='h-[2rem]' {...field} />
                )}
              />
            </div>
            <div>
              <Label className='text-sm !font-normal m-1'>Order Date</Label>
              <Controller
                control={control}
                name='order_date'
                render={({ field }) => (
                  <RangePicker
                    className='!px-2.5 !py-1.5 h-[2rem]'
                    placeholder={['Start Date', 'End Date']}
                    format='MM/DD/YYYY'
                    {...field}
                    onChange={(_, dateStrings: [string, string]) => {
                      console.log(dateStrings);
                      field.onChange(dateStrings);
                    }}
                    value={
                      field.value
                        ? [
                            field.value[0] ? dayjs(field.value[0]) : null,
                            field.value[1] ? dayjs(field.value[1]) : null
                          ]
                        : null
                    }
                  />
                )}
              />
            </div>
            <div>
              <Label className='text-sm !font-normal m-1'>Created At</Label>
              <Controller
                control={control}
                name='created_at'
                render={({ field }) => (
                  <RangePicker
                    className='!px-2.5 !py-1.5 h-[2rem]'
                    placeholder={['Start Date', 'End Date']}
                    format='MM/DD/YYYY'
                    {...field}
                    onChange={(_, dateStrings: [string, string]) => {
                      console.log(dateStrings);
                      field.onChange(dateStrings);
                    }}
                    value={
                      field.value
                        ? [
                            field.value[0] ? dayjs(field.value[0]) : null,
                            field.value[1] ? dayjs(field.value[1]) : null
                          ]
                        : null
                    }
                  />
                )}
              />
            </div>
            <div>
              <Label className='text-sm !font-normal m-1'>Status</Label>
              <Controller
                name='status'
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    className='w-[200px] h-[2rem]'
                    options={orderStatuses?.ups_status.map((val: string) => ({
                      label: val,
                      value: val
                    }))}
                  />
                )}
              />
            </div>
            <div>
              <Label className='text-sm !font-normal m-1'>Order Source</Label>
              <Controller
                name='order_source'
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    className='w-[160px] h-[2rem]'
                    options={[
                      { label: 'System Generated', value: 'System Generated' },
                      { label: 'Sheet', value: 'Sheet' }
                    ]}
                  />
                )}
              />
            </div>
            <div>
              <Label className='text-sm !font-normal m-1'>
                Order Delivered
              </Label>
              <Controller
                name='order_delivered'
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    className='w-[140px] h-[2rem]'
                    options={[
                      { label: 'Yes', value: 'true' },
                      { label: 'No', value: 'false' }
                    ]}
                  />
                )}
              />
            </div>
            <Button className='w-max px-3.5 h-[35px] mt-6'>
              <SearchOutlined className='!text-white' />
            </Button>
            <button
              onClick={() =>
                reset({
                  tracking_number: '',
                  email: '',
                  order_date: null,
                  status: undefined,
                  order_delivered: undefined,
                  created_at: null,
                  order_source: ''
                })
              }
              className='border border-orange rounded py-2 px-3 mt-6'
            >
              <MdOutlineFilterAltOff className='text-orange' />
            </button>
          </div>
        </form>
        <div className='overflow-auto h-[calc(100vh_-_325px)]'>
          <DataTable
            isLoading={isLoading}
            data={customerMemorizedData}
            columns={columns(setSortingDetails, sortingDetails)}
          />
        </div>
      </div>
      <Pagination
        isLoading={isLoading}
        totalCount={orderData?.pagination.total_count}
        totalPage={orderData?.pagination.total_pages}
        perPage={orderData?.pagination.per_page}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
}

const columns = (
  setSortingDetails: any,
  sortingDetails: any
): ColumnDef<Order>[] => [
  {
    header: 'Name',
    accessorKey: 'first_name',
    cell: (info) => (
      <a
        href={info.row.original.practice_better_profile}
        rel='noopener noreferrer'
        target='_blank'
        className='flex items-center gap-2'
      >
        <p className='underline text-orange underline-offset-4'>
          {joinName(info.getValue<string>(), info.row.original.last_name)}
        </p>
      </a>
    )
  },
  {
    header: 'Email',
    accessorKey: 'email',
    cell: (info) => (
      <Link
        to={paths.customer(
          info.row.original.practice_better_profile.split('clients/')[1]
        )}
      >
        <p className='text-orange underline truncate max-w-32'>
          <Tooltip title={info.getValue<string>()}>
            {info.getValue<string>()}
          </Tooltip>
        </p>
      </Link>
    )
  },
  {
    header: 'Medication',
    accessorKey: 'medication',
    cell: (info) =>
      info.getValue() && (
        <p className='border border-blue rounded-full py-1 px-2 text-xs text-blue bg-light-blue text-center'>
          {info.getValue() as string}
        </p>
      )
  },
  // {
  //   header: 'Initial Dose (mg/week)',
  //   accessorKey: 'initial_dose'
  // },
  {
    header: () => (
      <div className='flex items-center'>
        <span className='px-1 text-nowrap'>Order Date</span>
        <FaSort
          className='cursor-pointer'
          onClick={() =>
            setSortingDetails({
              sort_by: 'order_date',
              sort_order:
                sortingDetails?.sort_by === 'order_date' &&
                sortingDetails?.sort_order === 'desc'
                  ? 'asc'
                  : 'desc'
            })
          }
        />
      </div>
    ),
    accessorKey: 'order_date',
    cell: (info) => formatDate(info.getValue() as string)
  },
  { header: 'Pharmacy', accessorKey: 'pharmacy' },
  {
    header: 'Tracking No.',
    accessorKey: 'tracking_number',
    cell: (info) => (
      <Link
        to={`https://www.ups.com/track?loc=en_US&tracknum=${info.getValue()}`}
        className='text-orange underline underline-offset-4 cursor-pointer'
        target='_blank'
      >
        <Tooltip title='Click to open on UPS'>
          {info.getValue<string>()}
        </Tooltip>
      </Link>
    )
  },
  {
    header: () => (
      <div className='flex items-center'>
        <span className='px-1 text-nowrap'>Order Status</span>
        <FaSort
          className='cursor-pointer'
          onClick={() =>
            setSortingDetails({
              sort_by: 'ups_status',
              sort_order:
                sortingDetails?.sort_by === 'ups_status' &&
                sortingDetails?.sort_order === 'desc'
                  ? 'asc'
                  : 'desc'
            })
          }
        />
      </div>
    ),
    accessorKey: 'ups_status',
    cell: (info) =>
      info.getValue() && (
        <p
          className={`border rounded-full py-1 px-2 text-xs text-center w-[8rem] ${getStatusColorClasses(info.getValue() as string)}`}
        >
          {info.getValue() as string}
        </p>
      )
  },
  { header: 'Order Source', accessorKey: 'order_source' },
  // { header: 'Units', accessorKey: 'units' },
  // {
  //   header: 'Vial Size (mg)',
  //   accessorKey: 'vial_size',
  //   cell: (info) => `${info.getValue() as string}`
  // },
  // {
  //   header: 'Type',
  //   accessorKey: 'parent_order_id',
  //   cell: (info) => (info.getValue() ? 'Child' : 'Parent')
  // },
  {
    header: 'Pharmacy sheet addition',
    accessorKey: 'added_to_sheet',
    cell: (info) => (
      <p
        className={`border rounded-full py-1 px-2 text-xs text-center w-[3rem] ${getStatusColorClasses(info.getValue() ? 'Yes' : 'No')}`}
      >
        {info.getValue() ? 'Yes' : 'No'}
      </p>
    )
  },
  // {
  //   header: 'Order Created Email Sent',
  //   accessorKey: 'order_created_email_sent',
  //   cell: (info) => ((info.getValue() as string) ? 'Yes' : 'No')
  // },
  // {
  //   header: 'Tracking Sms Sent',
  //   accessorKey: 'sms_sent',
  //   cell: (info) => ((info.getValue() as string) ? 'Yes' : 'No')
  // },
  // {
  //   header: 'Order Tracking Email Sent',
  //   accessorKey: 'order_tracking_email_sent',
  //   cell: (info) => ((info.getValue() as string) ? 'Yes' : 'No')
  // },
  {
    header: () => (
      <div className='flex items-center'>
        <span className='px-1 text-nowrap'>Created At</span>
        <FaSort
          className='cursor-pointer'
          onClick={() =>
            setSortingDetails({
              sort_by: 'created_at',
              sort_order:
                sortingDetails?.sort_by === 'created_at' &&
                sortingDetails?.sort_order === 'desc'
                  ? 'asc'
                  : 'desc'
            })
          }
        />
      </div>
    ),
    accessorKey: 'created_at',
    cell: (info) => formatDate(info.getValue() as string)
  },
  {
    header: () => (
      <div className='flex items-center'>
        <span className='px-1 text-nowrap'>Updated At</span>
        <FaSort
          className='cursor-pointer'
          onClick={() =>
            setSortingDetails({
              sort_by: 'updated_at',
              sort_order:
                sortingDetails?.sort_by === 'updated_at' &&
                sortingDetails?.sort_order === 'desc'
                  ? 'asc'
                  : 'desc'
            })
          }
        />
      </div>
    ),
    accessorKey: 'updated_at',
    cell: (info) => formatDate(info.getValue() as string)
  },
  {
    header: 'Action',
    accessorKey: '',
    cell: (info) => (
      <div className='flex align-items gap-3'>
        <Drawer
          styles={{ header: { display: 'flex', flexDirection: 'row-reverse' } }}
          width='50%'
          button={
            <>
              <EditOutlined className='mr-1' />
              Edit
            </>
          }
          title='Edit Order Details'
          closeIcon={<ArrowRightOutlined />}
        >
          <OrderForm info={info.row.original} />
        </Drawer>
        <Link
          to={paths.customer(
            info.row.original.practice_better_profile.split('clients/')[1]
          )}
          className='text-orange underline-offset-4'
        >
          View
        </Link>
        {info.row.original?.tracking_number && (
          <Drawer
            styles={{
              header: { display: 'flex', flexDirection: 'row-reverse' }
            }}
            width='50%'
            button={
              <>
                <EyeOutlined />
              </>
            }
            title='Order Timeline'
            closeIcon={<ArrowRightOutlined />}
          >
            <OrderTimeline orderId={info.row.original.id} />
          </Drawer>
        )}
      </div>
    )
  }
];
