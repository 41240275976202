import React from 'react';
import logo from 'assets/images/logo.svg';
import { Avatar, Dropdown, MenuProps, Space } from 'antd';
import paths from 'constants/path';
import NavLinkItem from './components/NavLinkItem';
import { Link } from 'react-router-dom';
import { getCookie, removeCookie } from 'utils/cookies';
import { ArrowLeftOutlined, ProfileOutlined } from '@ant-design/icons';

const items: MenuProps['items'] = [
  {
    label: (
      <Link to='/profile' className='p-2 !text-orange font-semibold'>
        <ProfileOutlined className='mr-2' />
        View Profile
      </Link>
    ),
    key: '0'
  },
  {
    label: (
      <Link
        to='/login'
        className='p-2 !text-orange font-semibold'
        onClick={() => removeCookie('token')}
      >
        <ArrowLeftOutlined className='mr-2' />
        Log Out
      </Link>
    ),
    key: '1'
  }
];

export default function Header() {
  const role = getCookie('role');

  const getNavItems = () => {
    switch (role) {
      case 'Practitioner':
        return (
          <>
            <NavLinkItem path={paths.customers}>Customers</NavLinkItem>
            <NavLinkItem path={paths.orders}>Orders</NavLinkItem>
            <NavLinkItem path={paths.initialConsultations}>
              Initial Consultations
            </NavLinkItem>
            <NavLinkItem path={paths.followups}>Follow-ups</NavLinkItem>
            <NavLinkItem path={paths.tasks}>Tasks</NavLinkItem>
          </>
        );
      default:
        return (
          <>
            <NavLinkItem path={paths.customers}>Customers</NavLinkItem>
            <NavLinkItem path={paths.orders}>Orders</NavLinkItem>
            {/* <NavLinkItem path={paths.checkins}>CheckIns</NavLinkItem> */}
            <NavLinkItem path={paths.chatbot}>ChatBot</NavLinkItem>
            {/* <NavLinkItem path={paths.formRequest}>Form Request</NavLinkItem> */}
            <NavLinkItem path={paths.initialConsultations}>
              Initial Consultations
            </NavLinkItem>
            {/* <NavLinkItem path={paths.virtualFollowups}>Virtual Follow-ups</NavLinkItem> */}
            <NavLinkItem path={paths.followups}>Follow-ups</NavLinkItem>

            <NavLinkItem path={paths.aiPrompts}>Ai Prompts</NavLinkItem>
            <NavLinkItem path={paths.invoices}>Invoices</NavLinkItem>
            <NavLinkItem path={paths.tasks}>Tasks</NavLinkItem>
            <NavLinkItem path={paths.settings}>Settings</NavLinkItem>
          </>
        );
    }
  };
  return (
    <div className='bg-dark-blue sticky top-0 z-50'>
      <div className='py-2 px-8 flex justify-between items-center'>
        <Link to='/'>
          <img src={logo} alt='Logo' className='w-[110px] h-[42px]' />
        </Link>
        <div className='flex gap-10'>{getNavItems()}</div>
        <div className='flex items-center gap-2 cursor-pointer'>
          <Dropdown menu={{ items }} trigger={['click']}>
            <p>
              <Space>
                <Avatar src='https://api.dicebear.com/7.x/miniavs/svg?seed=1' />
                <div>
                  <p className='text-orange text-sm font-medium'>
                    {getCookie('fullName')}
                  </p>
                  <p className='text-xs text-white'>{getCookie('role')}</p>
                </div>
              </Space>
            </p>
          </Dropdown>
        </div>
      </div>
    </div>
  );
}
