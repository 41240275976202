import { ICReportsFilterInput, ICReportsSortingDetails } from 'types/ICReports';
import { request } from 'utils/api';

export const getICReports = async (
  currentPage: number,
  icReportFilters: ICReportsFilterInput,
  sortingDetails: ICReportsSortingDetails,
  downloadData?: boolean
) => {
  const data = await request({
    url: '/reports/ic-analytics',
    method: 'GET',
    params: {
      page: currentPage,
      ...icReportFilters,
      ...sortingDetails,
      download: downloadData ?? false
    }
  });
  return data;
};
